import * as THREE from 'three';
import { gsap } from 'gsap';
import { isMobile, resetCameraPosition } from './pages.js';  // Importing resetCameraPosition
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { animateCameraToBottom, animateCameraToTop } from './script.js';
import { createPortalPlane } from './portals.js';
import { createRoundedPlane } from './pages.js';
import { importGLTFAnimatedModel, animateModelsOnHover } from './importModels.js';
import { GAMEDEVS_BudgetModelURL, GAMEDEVS_IdeaModelURL, GAMEDEVS_RocketModelURL, GAMEDEVS_LegalModelURL, GAMEDEVS_MarketingModelURL, GAMEDEVS_MentorsModelURL, GAMEDEVS_NetworkModelURL } from './importModels.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
let renderer, scene, camera;
const pointLightHelpers = [];
export let isAnimatingCamera_gameDevs = false;
const parameters = {}
parameters.size = 1
const positionsArray = [];


const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dracoLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dracoLoader);

const animatedButtons = [];

// Shader Material with rotation
let galaxyThorusMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
        rotationSpeed: { value: 0.5 }
    },
    vertexShader: `
        attribute vec3 galaxyOffset;

        uniform vec3 uMouse;
        uniform float uTime;
        uniform float rotationSpeed;

        void main() {
            vec3 worldPosition = position + galaxyOffset;

            // Translate to origin (relative to galaxyOffset)
            vec3 positionRelativeToCenter = worldPosition - galaxyOffset;

            // --- Rotation around Y-axis (animation) ---
            float angleY = uTime * rotationSpeed;
            float cosThetaY = cos(angleY);
            float sinThetaY = sin(angleY);
            vec3 rotatedPositionY;
            rotatedPositionY.x = cosThetaY * positionRelativeToCenter.x + sinThetaY * positionRelativeToCenter.z;
            rotatedPositionY.y = positionRelativeToCenter.y;
            rotatedPositionY.z = -sinThetaY * positionRelativeToCenter.x + cosThetaY * positionRelativeToCenter.z;

            // --- Rotation around X-axis (static 30 degrees) ---
            float angleX = radians(330.0); // Convert 30 degrees to radians
            float cosThetaX = cos(angleX);
            float sinThetaX = sin(angleX);
            vec3 rotatedPosition;
            rotatedPosition.x = rotatedPositionY.x;
            rotatedPosition.y = cosThetaX * rotatedPositionY.y - sinThetaX * rotatedPositionY.z;
            rotatedPosition.z = sinThetaX * rotatedPositionY.y + cosThetaX * rotatedPositionY.z;

            // Translate back to world position
            rotatedPosition += galaxyOffset;

            // Use rotatedPosition for distance and direction calculations
            float dist = distance(rotatedPosition, uMouse);
            float displacement = 100.0 * exp(-dist * 0.01);
            vec3 direction = normalize(rotatedPosition - uMouse);
            vec3 newPosition = rotatedPosition + direction * displacement;

            vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
            gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
            gl_Position = projectionMatrix * mvPosition;
        }
    `,

    fragmentShader: `
        void main() {
            float d = length(gl_PointCoord - vec2(0.5));
            if (d > 0.5) discard;
            gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.0);
        }
    `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});
let ThorusPoints;
// Torus particle generation
const generateThorusGalaxy = (size, offset, particleCount) => {
    if (isMobile()) return; // Skip this for mobile devices
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    const R = size.x / 2; // Major radius
    const r = size.y / 4; // Minor radius
    const rMin = r * 0.8;
    const rMax = r * 1.2;

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        const u = Math.random() * Math.PI * 2;
        const v = Math.random() * Math.PI * 2;
        const rPrime = rMin + Math.random() * (rMax - rMin);

        positions[i3] = (R + rPrime * Math.cos(v)) * Math.cos(u); // x
        positions[i3 + 1] = (R + rPrime * Math.cos(v)) * Math.sin(u); // y
        positions[i3 + 2] = rPrime * Math.sin(v); // z

        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyThorusMaterial);
    points.frustumCulled = false;
    ThorusPoints = points;
    scene.add(points);
};
function Enable_ThorusPoints() {
    if (ThorusPoints)
        ThorusPoints.visible = true;
}
function Disable_ThorusPoints() {
    if (ThorusPoints)
        ThorusPoints.visible = false;
}
let galaxyMaterial;
galaxyMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
    },
    vertexShader: `
            // Add 'galaxyOffset' as an attribute
            attribute vec3 galaxyOffset;

            uniform vec3 uMouse;
            uniform float uTime;

            void main() {
                // Calculate the world position by adding the galaxy offset
                vec3 worldPosition = position + galaxyOffset;

                // Use worldPosition for distance and direction calculations
                float dist = distance(worldPosition, uMouse);
                float displacement = 100.0 * exp(-dist * 0.01);
                vec3 direction = normalize(worldPosition - uMouse);
                vec3 newPosition = worldPosition + direction * displacement;

                vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
                gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
    fragmentShader: `
            void main() {
                float d = length(gl_PointCoord - vec2(0.5));
                if (d > 0.5) discard;
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.5);
            }
        `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});
const generateGalaxy = (size, offset, particleCount) => {
    /**
     * Geometry
     */
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        // Generate positions without adding the offset
        positions[i3] = (Math.random() - 0.5) * size.x; // x
        positions[i3 + 1] = (Math.random() - 0.5) * size.y; // y
        positions[i3 + 2] = (Math.random() - 0.5) * size.z; // z

        // Set the galaxy offset for each particle
        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyMaterial);
    scene.add(points);
};



// Blurry glass material
const materialOptions = {
    transmission: 1,
    roughness: 0.6,
    emissive: 0xffffff,
    emissiveIntensity: 0.01,
    opacity: 0.5,
};
const blurryMaterial = new THREE.MeshPhysicalMaterial(materialOptions);





let cssScene, cssRenderer; // Scene for CSS3DObjects
let portal1;
let portalCamera, portalCube;
let marketingModel, legalModel, mentoringModel, networkModel;
let ideaModel, budgetModel, rocketModel;
// INITIALIZATION
export async function initNewScene(FromScene, FromRenderer, FromCamera, FromCssRenderer, FromCssScene) {
    fadeInCanvas();
    FromCamera.position.set(0, -1, 4.5);
    await new Promise((resolve) => { setTimeout(resolve, 200); });

    scene = FromScene;
    renderer = FromRenderer;
    camera = FromCamera;
    cssRenderer = FromCssRenderer;
    cssScene = FromCssScene;
    // Add directional light
    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight1.position.set(1, 1, 1).normalize();
    scene.add(directionalLight1);

    generateThorusGalaxy(new THREE.Vector3(5, 5, 5), new THREE.Vector3(5, -7.5, -5), 2500);
    Disable_ThorusPoints();
    generateGalaxy(new THREE.Vector3(100, 50, 20), new THREE.Vector3(0, 0, -20), 1000);
    // Add PointLightHelpers and labels
    //addPointLightHelpers(); // Add all the point lights helpers placeholders
    resetCameraPosition(camera); // Reset camera position

    await setupGamedevHowWorksModel();

    if (isMobile()) {
        await setupSlides_mobile();
    }
    else {
        await setupSlides();
    }
    if (isMobile()) {
        NetworkModelPosition = new THREE.Vector3(0, -1, 0.5);
        NetworkModelScale = new THREE.Vector3(0.5, 0.5, 0.5);

        networkModel = await importGLTFAnimatedModel(GAMEDEVS_NetworkModelURL, NetworkModelPosition, NetworkModelScale, gltfLoader);
    }
    else {
        NetworkModelPosition = new THREE.Vector3(2.3 + (1 / (window.innerWidth / 100)), 1.3, 0.5);
        NetworkModelScale = new THREE.Vector3(0.7, 0.7, 0.7);

        networkModel = await importGLTFAnimatedModel(GAMEDEVS_NetworkModelURL, NetworkModelPosition, NetworkModelScale, gltfLoader);
    }
    scene.add(networkModel);
    networkModel.lookAt(camera.position);
    //add event listener for resizing

    SetupLighting();
    animate();
    renderer.render(scene, camera);
}

function SetupLighting() {
    // Add directional light
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

}
async function setupGamedevHowWorksModel() {
    let NetworkModelPosition, NetworkModelScale, ideaModelPosition, ideaModelScale, budgetModelPosition, budgetModelScale, rocketModelPosition, rocketModelScale;
    if (isMobile()) {
        ideaModelPosition = new THREE.Vector3(0, -15, 0);
        ideaModelScale = new THREE.Vector3(0.55, 0.55, 0.55);
        budgetModelPosition = new THREE.Vector3(0, -18, 0);
        budgetModelScale = new THREE.Vector3(0.65, 0.65, 0.65);
        rocketModelPosition = new THREE.Vector3(0, -21.4, 0);
        rocketModelScale = new THREE.Vector3(0.17, 0.17, 0.17);

    }
    else {
        ideaModelPosition = new THREE.Vector3(-3.7, -14, 0);
        ideaModelScale = new THREE.Vector3(0.55, 0.55, 0.55);
        budgetModelPosition = new THREE.Vector3(0, -13.5, 0);
        budgetModelScale = new THREE.Vector3(0.65, 0.65, 0.65);
        rocketModelPosition = new THREE.Vector3(3.7, -13.5, 0);
        rocketModelScale = new THREE.Vector3(0.17, 0.17, 0.17);

    }
    //Come funziona?
    ideaModel = await importGLTFAnimatedModel(GAMEDEVS_IdeaModelURL, ideaModelPosition, ideaModelScale, gltfLoader);
    scene.add(ideaModel);
    budgetModel = await importGLTFAnimatedModel(GAMEDEVS_BudgetModelURL, budgetModelPosition, budgetModelScale, gltfLoader);
    scene.add(budgetModel);
    rocketModel = await importGLTFAnimatedModel(GAMEDEVS_RocketModelURL, rocketModelPosition, rocketModelScale, gltfLoader);
    scene.add(rocketModel);
    // IDEA MODEL LABEL
    const ideaLabelElement = document.createElement('div');
    ideaLabelElement.className = 'labelExplainer StratosDetailsCard';
    ideaLabelElement.innerHTML = `
      <h2>
        Hai un'idea per un gioco?</br>
        Fantastico!
      </h2>
      <p>
        Il primo passo è descriverla bene nei punti che la rendono unica e la caratterizzano.
      </p>
    `;
    const ideaLabel3D = new CSS3DObject(ideaLabelElement);
    // Adjust position relative to the model's origin
    ideaLabel3D.position.set(ideaModelPosition.x, ideaModelPosition.y - 0.7, ideaModelPosition.z);
    cssScene.add(ideaLabel3D);
    // BUDGET MODEL LABEL
    const budgetLabelElement = document.createElement('div');
    budgetLabelElement.className = 'labelExplainer StratosDetailsCard';
    budgetLabelElement.innerHTML = `
      <h2>Poi devi pensare a quanto costerà creare il tuo gioco.</h2>
      <p>Questo ti servirà per chiedere i soldi necessari per realizzarlo.</p>
    `;
    const budgetLabel3D = new CSS3DObject(budgetLabelElement);
    budgetLabel3D.position.set(budgetModelPosition.x, budgetModelPosition.y - 1.2, budgetModelPosition.z);
    cssScene.add(budgetLabel3D);
    // ROCKET MODEL LABEL
    const rocketLabelElement = document.createElement('div');
    rocketLabelElement.className = 'labelExplainer StratosDetailsCard';
    rocketLabelElement.innerHTML = `
      <h2>Quando avrai raccolto tutti i soldi, potrai accedere a tutti i servizi di Stratos!</h2>
      <p>
        Avrai un esperto ad aiutarti, sarai sollevato di tutti i lavori burocratici
        e ti potrai concentrare solamente sullo sviluppo.
      </p>
    `;
    const rocketLabel3D = new CSS3DObject(rocketLabelElement);
    rocketLabel3D.position.set(rocketModelPosition.x, rocketModelPosition.y - 1.2, rocketModelPosition.z);
    cssScene.add(rocketLabel3D);
    let scale = 0.006;
    ideaLabel3D.scale.set(scale, scale, scale);
    budgetLabel3D.scale.set(scale, scale, scale);
    rocketLabel3D.scale.set(scale, scale, scale);

}

function createButton(text, position, onClick) {
    var div = document.createElement('div');
    div.className = 'boxedTitle';
    var h2 = document.createElement('h2');
    h2.innerHTML = text;
    div.appendChild(h2);

    // Add event listener
    h2.style.cursor = 'pointer'; // Change cursor to pointer
    div.style.pointerEvents = 'auto'; // Enable pointer events
    div.style.zIndex = 10; // Ensure the button is always on top
    h2.addEventListener('click', function (event) {
        console.log('Button clicked:', text);
        onClick();
    });


    var object = new CSS3DObject(div);
    // Adjust scale and position for better interaction
    object.scale.set(0.005, 0.005, 0.005);
    object.position.copy(position);
    return object;
}


// Function to create a slide
function createSlide(h1Text, h2Text, pText, position) {

    var div = document.createElement('div');
    div.className = 'StratosOffers heading2';

    var h1 = document.createElement('h1');
    h1.innerHTML = h1Text;
    div.appendChild(h1);

    var span = document.createElement('span');

    var h2 = document.createElement('h2');
    h2.innerHTML = h2Text;
    span.appendChild(h2);

    var p = document.createElement('p');
    p.innerHTML = pText;
    span.appendChild(p);

    div.appendChild(span);

    // Find the container with class 'StratosOfferContainer'
    var container = document.querySelector('.StratosOfferContainer');
    if (container) {
        container.appendChild(div);
    }
    var object = new CSS3DObject(div);
    object.scale.set(0.01, 0.01, 0.01); // Adjusted scale for better visibility
    object.position.copy(position);
    return object;
}


function setModelOpacity(model, opacity) {
    model.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.material) {
                child.material.transparent = true;
                child.material.opacity = opacity;
                child.material.needsUpdate = true;
            }
        }
    });
}



function switchSlide(newSlide, newModel) {
    // Update slides
    currentSlide.visible = false;
    newSlide.visible = true;
    currentModel.position.z -= 1;
    newModel.position.z += 1;
    // Update models
    setModelOpacity(currentModel, 0);
    setModelOpacity(newModel, 1);

    // Update current references
    currentSlide = newSlide;
    currentModel = newModel;
}



var buttonPositions, slidePosition;
// Positions
if (isMobile()) {
    buttonPositions = [
        new THREE.Vector3(0, -31, 0.3), // Left
        new THREE.Vector3(0, -31.5, 0.3),  // Center
        new THREE.Vector3(0, -32, 0.3)   // Right
    ];
}
else {
    buttonPositions = [
        new THREE.Vector3(1.7, -21.2, 0), // Left
        new THREE.Vector3(2.7, -21.2, 0),  // Center
        new THREE.Vector3(3.7, -21.2, 0)   // Right
    ];
}
if (isMobile()) {
    slidePosition = new THREE.Vector3(0, -26, 0);
}
else {
    slidePosition = new THREE.Vector3(0, -18.6, 0);
}

var currentSlide;
var currentModel;

async function setupSlides() {
    //Cosa offriamo? 
    const roundedPlaneGeometry = createRoundedPlane(10, 3.5, 0.5);
    const roundedPlane = new THREE.Mesh(roundedPlaneGeometry, blurryMaterial);
    roundedPlane.position.set(0, -20, 0);
    scene.add(roundedPlane);
    marketingModel = await importGLTFAnimatedModel(GAMEDEVS_MarketingModelURL, new THREE.Vector3(2.3, -19.6, 0.5), new THREE.Vector3(0.6, 0.6, 0.6), gltfLoader);
    scene.add(marketingModel);
    legalModel = await importGLTFAnimatedModel(GAMEDEVS_LegalModelURL, new THREE.Vector3(2.5, -19.6, -0.5), new THREE.Vector3(.06, .06, .06), gltfLoader);
    scene.add(legalModel);
    mentoringModel = await importGLTFAnimatedModel(GAMEDEVS_MentorsModelURL, new THREE.Vector3(2.3, -19.6, -0.5), new THREE.Vector3(0.4, 0.4, 0.4), gltfLoader);
    scene.add(mentoringModel);

    var slide1 = createSlide(
        'Cosa offriamo?',
        'Comunicare è la chiave',
        'Non basta creare un buon gioco: bisogna farlo conoscere. Su Stratos, puoi affidarti a un team di marketing con esperienza nel settore del gaming, per garantire al tuo progetto la visibilità che merita.',
        slidePosition
    );

    var slide2 = createSlide(
        'Cosa offriamo?',
        'Alla burocrazia pensiamo noi',
        'La sicurezza del tuo progetto è la nostra priorità. Con Stratos, avrai accesso ad un supporto legale specializzato, tutelando il tuo lavoro in ogni fase dello sviluppo.',
        slidePosition
    );

    var slide3 = createSlide(
        'Cosa offriamo?',
        'Consulenza esperta',
        'Avere un supporto esperto può fare la differenza. Grazie ai nostri mentor, potrai beneficiare di una guida continua e affrontare con sicurezza le sfide del processo di sviluppo.',
        slidePosition
    );

    currentModel = marketingModel;
    currentSlide = slide1;
    // Add Slides to CSS Scene
    cssScene.add(slide1);
    cssScene.add(slide2);
    cssScene.add(slide3);


    // Set initial visibility
    slide1.visible = true;
    slide2.visible = false;
    slide3.visible = false;
    // Create Buttons with click handlers
    var button1 = createButton('Marketing', buttonPositions[0], function () {
        switchSlide(slide1, marketingModel);
    });

    var button2 = createButton('Legal', buttonPositions[1], function () {
        switchSlide(slide2, legalModel);
    });

    var button3 = createButton('Mentorship', buttonPositions[2], function () {
        switchSlide(slide3, mentoringModel);
    });
    // Add Buttons to CSS Scene
    cssScene.add(button1);
    cssScene.add(button2);
    cssScene.add(button3);

    // Set initial model opacities
    setModelOpacity(marketingModel, 1);
    setModelOpacity(legalModel, 0);
    setModelOpacity(mentoringModel, 0);
}
async function setupSlides_mobile() {
    //Cosa offriamo? 
    const roundedPlaneGeometry = createRoundedPlane(2.5, 7, 0.5);
    const roundedPlane = new THREE.Mesh(roundedPlaneGeometry, blurryMaterial);
    roundedPlane.position.set(0, -29, 0);
    scene.add(roundedPlane);
    marketingModel = await importGLTFAnimatedModel(GAMEDEVS_MarketingModelURL, new THREE.Vector3(0, -30, 0.5), new THREE.Vector3(0.4, 0.4, 0.4), gltfLoader);
    scene.add(marketingModel);
    legalModel = await importGLTFAnimatedModel(GAMEDEVS_LegalModelURL, new THREE.Vector3(0.15, -30, -0.5), new THREE.Vector3(.04, .04, .04), gltfLoader);
    scene.add(legalModel);
    mentoringModel = await importGLTFAnimatedModel(GAMEDEVS_MentorsModelURL, new THREE.Vector3(0, -30, -0.5), new THREE.Vector3(0.3, 0.3, 0.3), gltfLoader);
    scene.add(mentoringModel);

    var slide1 = createSlide(
        'Cosa offriamo?',
        'Comunicare è la chiave',
        'Non basta creare un buon gioco: bisogna farlo conoscere. Su Stratos, puoi affidarti a un team di marketing con esperienza nel settore del gaming, per garantire al tuo progetto la visibilità che merita.',
        slidePosition
    );

    var slide2 = createSlide(
        'Cosa offriamo?',
        'Alla burocrazia pensiamo noi',
        'La sicurezza del tuo progetto è la nostra priorità. Con Stratos, avrai accesso ad un supporto legale specializzato, tutelando il tuo lavoro in ogni fase dello sviluppo.',
        slidePosition
    );

    var slide3 = createSlide(
        'Cosa offriamo?',
        'Consulenza esperta',
        'Avere un supporto esperto può fare la differenza. Grazie ai nostri mentor, potrai beneficiare di una guida continua e affrontare con sicurezza le sfide del processo di sviluppo.',
        slidePosition
    );

    currentModel = marketingModel;
    currentSlide = slide1;
    // Add Slides to CSS Scene
    cssScene.add(slide1);
    cssScene.add(slide2);
    cssScene.add(slide3);


    // Set initial visibility
    slide1.visible = true;
    slide2.visible = false;
    slide3.visible = false;
    // Create Buttons with click handlers
    var button1 = createButton('Marketing', buttonPositions[0], function () {
        switchSlide(slide1, marketingModel);
    });

    var button2 = createButton('Legal', buttonPositions[1], function () {
        switchSlide(slide2, legalModel);
    });

    var button3 = createButton('Mentorship', buttonPositions[2], function () {
        switchSlide(slide3, mentoringModel);
    });
    // Add Buttons to CSS Scene
    cssScene.add(button1);
    cssScene.add(button2);
    cssScene.add(button3);

    // Set initial model opacities
    setModelOpacity(marketingModel, 1);
    setModelOpacity(legalModel, 0);
    setModelOpacity(mentoringModel, 0);
}








let cameraAnimationStartTime = 0;
let cameraAnimationDuration = 0.5; // Duration in seconds
let cameraStartPosition = new THREE.Vector3();
let cameraEndPosition = new THREE.Vector3();
let cameraStartQuaternion = new THREE.Quaternion();
let cameraEndQuaternion = new THREE.Quaternion();
export function animateCameraToBottom_gamedevs() {
    //log
    console.log('Animating camera to bottom');
    if (isAnimatingCamera_gameDevs) return;
    Enable_ThorusPoints();
    isAnimatingCamera_gameDevs = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation
    cameraEndPosition.set(0, -7, 4); // Move down and closer
    camera.lookAt(0, 1.5, 0); // Adjust to look at a lower point
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}
export function animateCameraToTop_gamedevs() {
    if (isAnimatingCamera_gameDevs) return;
    setTimeout(() => {
        Disable_ThorusPoints();
    }, 100);
    isAnimatingCamera_gameDevs = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation (original position)
    cameraEndPosition.set(0, 1.5, 4.5); // Original position
    camera.lookAt(0, -7.5, 0); // Original lookAt
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}

const labelText = [
    'Writer',
    'Producer',
    '3D Artist',
    'Programmer',
    'Game & Level Designer',
    'Sound Designer',
    '2D Artist',
    'UX Designer'
];
function addPointLightHelpers() {
    if (isMobile()) return; // Skip this for mobile devices
    const lightsCount = 7; // Number of lights
    const spacing = 2.2; // Spacing between lights
    const height = window.innerHeight;
    if (height < 800) return; // Skip this for small screens (the models would overlap with the text)
    const offset = 4 / (height / 108);
    console.log(offset);
    for (let i = 0; i < lightsCount; i++) {
        const x = (i - (lightsCount - 1) / 2) * spacing; // Calculate x position for a line

        // Create PointLight and its helper
        const pointLight = new THREE.PointLight(0xffffff, 1, 10);
        pointLight.position.set(x, -2.1, -0.75); // Set position in a line
        scene.add(pointLight);

        const helper = new THREE.PointLightHelper(pointLight, 0.5);
        scene.add(helper);
        pointLightHelpers.push(helper);

        // Create an invisible sphere for collision detection
        const sphereGeometry = new THREE.SphereGeometry(0.75, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ visible: false });
        const collisionSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        collisionSphere.position.copy(pointLight.position.x, pointLight.position.y - offset, pointLight.position.z);
        scene.add(collisionSphere);

        // Add hover interaction
        addHoverEffect(collisionSphere, helper);

        // Create a label for this point light
        createLabelForLight(pointLight, labelText[i]);
    }
}

function createLabelForLight(pointLight, textContent) {
    // Create the label element
    const labelElement = document.createElement('div');
    labelElement.className = 'label';
    labelElement.innerHTML = `
        <h1>${textContent}</h1>
        <h2>Subheading</h2>
        <p>Lorem Ipsum.</p>
    `;

    // Convert the label into a CSS3DObject
    const labelObject = new CSS3DObject(labelElement);
    labelObject.position.copy(pointLight.position);
    labelObject.scale.set(0.01, 0.01, 0.01); // Adjust scale as needed
    cssScene.add(labelObject);
    return labelObject;
}

function addHoverEffect(collisionSphere, helper) {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseMove(event) {
        // Update mouse coordinates for raycasting
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // Raycast from camera to the mouse position
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObject(collisionSphere);

        if (intersects.length > 0) {
            // Animate rotation using GSAP when hovered
            gsap.to(helper.rotation, { y: helper.rotation.y + Math.PI, duration: 1 });
        }
    }

    // Attach the event listener for mouse movement
    window.addEventListener('mousemove', onMouseMove);
}
const mouseposition = new THREE.Vector2();

window.addEventListener('mousemove', function (e) {
    mouseposition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouseposition.y = - (e.clientY / window.innerHeight) * 2 + 1;
});
const clock = new THREE.Clock();
const mouseWorldPosition = new THREE.Vector3();
const raycaster = new THREE.Raycaster();
function rotateModelToLookAtMouse() {

    // Step 1: Convert the mouse position to normalized device coordinates (-1 to +1)
    var mouseNDC = new THREE.Vector2();
    mouseNDC.x = -mouseposition.x;
    mouseNDC.y = -mouseposition.y;

    // Step 2: Unproject the mouse coordinates to get a point in 3D space
    var vector = new THREE.Vector3(mouseNDC.x, mouseNDC.y, 2.5); // z = 0.5 means halfway between near and far planes
    vector.unproject(camera);

    // Step 3: Calculate the direction from the camera to the unprojected point
    var dir = vector.sub(camera.position).normalize();

    // Step 4: Define the distance from the camera to the point where the object should look
    var distance = 3; // Adjust this value as needed

    // Step 5: Calculate the target position in 3D space
    var targetPosition = camera.position.clone().add(dir.multiplyScalar(distance));

    // Step 6: Rotate the networkModel to look at the target position
    networkModel.lookAt(targetPosition);
    //ROTATE THE MODEL 90 DEGREES
}

function animate() {
    requestAnimationFrame(animate);
    const elapsedTime = clock.getElapsedTime();
    const delta = clock.getDelta();
    rotateModelToLookAtMouse();

    // animate camera for scrolldown
    if (isAnimatingCamera_gameDevs) {
        let t = (elapsedTime - cameraAnimationStartTime) / cameraAnimationDuration;
        if (t >= 1) {
            t = 1;
            isAnimatingCamera_gameDevs = false;
        }

        camera.position.lerpVectors(cameraStartPosition, cameraEndPosition, t);
        camera.quaternion.slerpQuaternions(cameraStartQuaternion, cameraEndQuaternion, t);
    }
    // Update the uniforms for the galaxy
    const vector = new THREE.Vector3(mouseposition.x, mouseposition.y, 0.5);
    vector.unproject(camera);
    mouseWorldPosition.copy(vector);
    if (galaxyThorusMaterial) {
        galaxyThorusMaterial.uniforms.uMouse.value.copy(mouseWorldPosition);
        galaxyThorusMaterial.uniforms.uTime.value = elapsedTime;
    }
    animateModelsOnHover(animatedButtons, delta, raycaster, mouseposition, camera);
    // Render both scenes
    renderer.render(scene, camera);
    cssRenderer.render(cssScene, camera);
}


function fadeInCanvas() {
    gsap.to('canvas', {
        opacity: 1,
        duration: 0.5,
        delay: 1
    });
}
