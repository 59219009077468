import * as THREE from 'three';

function createPortalPlane(renderer, portalScene, portalCamera, options = {}) {
  const target = new THREE.WebGLRenderTarget(1024, 512);

  const width = options.width || 1;
  const height = options.height || 1;
  const position = options.position || new THREE.Vector3(0, 0, 0);
  const rotation = options.rotation || new THREE.Euler(0, 0, 0);
  const radius = options.radius || 1;
  const curvatureAmount = options.curvature || 0.5;
  const _texture = options._texture || null;
  const scene = portalScene;
  const backgroundURL = options.backgroundURL || null;

  const material = new THREE.ShaderMaterial({
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D target;
      varying vec2 vUv;
      void main() {
        gl_FragColor = texture2D(target, vUv);
      }
    `,
    uniforms: {
      target: { value: target.texture },
    },
  });

  // Create the rounded rectangle shape
  const shape = createRoundedRectShape(width, height, radius);
  const geometry = new THREE.ShapeGeometry(shape);

  const positionAttribute = geometry.attributes.position;
  const positions = positionAttribute.array;
  const uvs = new Float32Array((positions.length / 3) * 2);

  for (let i = 0, j = 0; i < positions.length; i += 3, j += 2) {
    const x = positions[i];
    const y = positions[i + 1];

    const u = (x + width / 2) / width;
    const v = (y + height / 2) / height;

    uvs[j] = u;
    uvs[j + 1] = v;

    const t = (x + width / 2) / width;
    const zOffset = Math.sin(t * Math.PI) * curvatureAmount;
    positions[i + 2] = zOffset;
  }

  positionAttribute.needsUpdate = true;
  geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

  const portalPlane = new THREE.Mesh(geometry, material);
  portalPlane.position.copy(position);
  portalPlane.rotation.copy(rotation);

  let isEnabled = true;

  function renderPortal() {
    if (!isEnabled) return;
    const currentRenderTarget = renderer.getRenderTarget();
    renderer.setRenderTarget(target);
    renderer.render(scene, portalCamera);
    renderer.setRenderTarget(currentRenderTarget);
  }

  function createRoundedRectShape(width, height, radius) {
    const x = -width / 2;
    const y = -height / 2;
    const shape = new THREE.Shape();
    shape.moveTo(x + radius, y);
    shape.lineTo(x + width - radius, y);
    shape.quadraticCurveTo(x + width, y, x + width, y + radius);
    shape.lineTo(x + width, y + height - radius);
    shape.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    shape.lineTo(x + radius, y + height);
    shape.quadraticCurveTo(x, y + height, x, y + height - radius);
    shape.lineTo(x, y + radius);
    shape.quadraticCurveTo(x, y, x + radius, y);
    return shape;
  }

  // Add a dispose function to free all resources
  function dispose() {
    // Disable any further rendering
    isEnabled = false;

    // Dispose portal plane geometry and material
    if (portalPlane) {
      if (portalPlane.geometry) {
        portalPlane.geometry.dispose();
      }
      if (portalPlane.material) {
        if (portalPlane.material.map) {
          portalPlane.material.map.dispose();
        }
        portalPlane.material.dispose();
      }
    }

    // Dispose the render target
    if (target) {
      target.dispose();
    }

    // Dispose background texture if present and is a texture
    if (scene && scene.background && scene.background.isTexture) {
      scene.background.dispose(); 
      scene.background = null; // Remove reference
    }

    // Remove references
    portalPlane.removeFromParent();
  }

  return { 
    portalPlane, 
    renderPortal,
    enable() { 
      isEnabled = true; 
      portalPlane.visible = true;  
    },
    disable() { 
      isEnabled = false; 
      portalPlane.visible = false;  
    },
    scene,
    backgroundURL,
    _texture,
    dispose // Add the dispose method here
  };
}

export { createPortalPlane };
