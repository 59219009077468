import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { initScroll, SetIsScrollable } from './scrolldown.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { importSocialDiscord, importBlurryGlass, importPortraitBase, importGLTFAnimatedModel, animateModelsOnHover } from './importModels.js';
import { GamersModelURL, GameDevsModelURL, InvestorsModelURL, socialDiscordURL, portraitBaseURL, LogoInstaModelURL, LogoDiscordModelURL, LogoFacebookModelURL, LogoLinkedinModelURL, importPortraitPartners } from './importModels.js';
import { RoadmapCityURL, RoadmapControllerURL, RoadmapMovingRocketURL, RoadmapPlanetURL, RoadmapStartingRocketURL } from './importModels.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { createRoundedPlane } from './pages.js';
import { isMobile } from './pages.js';
import { smoothScrollToTop } from './scrolldown.js';
import gsap from 'gsap';
let navMenuToggled = false;
export let isTransitioningtoscene = false;
//NOT THREEJS
function toggleMenu() {
    navMenuToggled = !navMenuToggled;
    document.querySelector('.nav-links').classList.toggle('active');
}

// Close the menu when clicking outside
document.addEventListener('click', function (event) {
    const menu = document.querySelector('.nav-links');
    const menuToggle = document.querySelector('.menu-toggle');

    if (!menu.contains(event.target) && !menuToggle.contains(event.target)) {
        menu.classList.remove('active');
    }
});

window.toggleMenu = toggleMenu;

const SETORBITCONTROLS = false;
const animatedButtons = [];
//rendering
const canvas = document.getElementById('three-canvas');
export const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });
renderer.shadowMap.enabled = true;
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

//scene and camera
export const scene = new THREE.Scene();
export const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.set(0, 1.5, 4.5);
camera.lookAt(0, 1, 0);
console.log(window.innerWidth, window.innerHeight);
//const axisHelper = new THREE.AxesHelper(5);
//scene.add(axisHelper);
const clock = new THREE.Clock();
var isActiveScene = true;
export let isAnimatingCamera = false;
let cameraAnimationStartTime = 0;
let cameraAnimationDuration = 0.5; // Duration in seconds
let cameraStartPosition = new THREE.Vector3();
let cameraEndPosition = new THREE.Vector3();
let cameraStartQuaternion = new THREE.Quaternion();
let cameraEndQuaternion = new THREE.Quaternion();
if (SETORBITCONTROLS) {
    const orbit = new OrbitControls(camera, renderer.domElement);
    orbit.update();
};
const textureLoader = new THREE.TextureLoader();
const texturePath = require('../assets/img/GradientBackground.png');
let gradientT;
textureLoader.load(
    texturePath,
    function (backgroundGradient) {
        // Apply texture as scene background
        scene.background = backgroundGradient;
        scene.background.colorSpace = THREE.SRGBColorSpace;
        gradientT = backgroundGradient;
        //darker the background

    },
    undefined,
    function (err) {
        console.error('An error happened while loading the texture.' + err.message);
    }
);


//add postprocessing

renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: set shadow map typ
const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);
composer.setSize(window.innerWidth, window.innerHeight);
const pixelRatio = renderer.getPixelRatio();
composer.addPass(new SMAAPass(window.innerWidth * pixelRatio, window.innerHeight * pixelRatio));
const outputPass = new OutputPass();
composer.addPass(outputPass);
const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera);
outlinePass.edgeStrength = 3.0; // Adjusts the thickness of the outline
outlinePass.edgeGlow = 0.0;
outlinePass.edgeThickness = 1.0; // Adjust the thickness of the lines
outlinePass.pulsePeriod = 0;
outlinePass.visibleEdgeColor.set("#000000"); // Black color for the visible edges
outlinePass.hiddenEdgeColor.set("#000000"); // Hidden edges are also black

composer.addPass(outlinePass);

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dracoLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dracoLoader);

// Set up CSS3DRenderer
const cssRenderer = new CSS3DRenderer();
cssRenderer.setSize(window.innerWidth, window.innerHeight);
cssRenderer.domElement.style.position = 'fixed';
cssRenderer.domElement.style.top = '50%';
cssRenderer.domElement.style.left = '50%';
cssRenderer.domElement.style.transform = 'translate(-50%, -50%)';
document.body.appendChild(cssRenderer.domElement);

// Create a separate scene for CSS3DObjects
const cssScene = new THREE.Scene();





//materials
const material = new THREE.MeshStandardMaterial(
    {
        color: 0xf0f0f0,
        side: THREE.DoubleSide,
        emissive: 0x000000,
    });
const material2 = new THREE.MeshStandardMaterial(
    {
        color: 0xf00000,
        side: THREE.DoubleSide,
        emissive: 0x000000,
    });
const boxmaterial = new THREE.MeshStandardMaterial(
    {
        color: 0x00ff55,
        emissive: 0xff0000,
        roughness: 0.1
    });
const blackMaterial = new THREE.MeshStandardMaterial(
    {
        color: 0x000000,
        emissive: 0x000000,
        roughness: 0.1
    });

const materialOptions = {
    transmission: 1,
    roughness: 0.6,
    emissive: 0xffffff,
    emissiveIntensity: 0.01,
    opacity: 0.5,
};
const blurryMaterial = new THREE.MeshPhysicalMaterial(materialOptions);

//geometry
const plane = new THREE.PlaneGeometry(100, 100);
const spherePlanet = new THREE.SphereGeometry(1, 64, 64);

//meshes

const planeMesh = new THREE.Mesh(plane, material);
const sphere = new THREE.Mesh(spherePlanet, blackMaterial);
sphere.position.set(0, -96, 0);
sphere.scale.set(90, 60, 90);
scene.add(sphere);


//IMPORT OBJECTS
let gamersModel, gameDevsModel, investorsModel, logoInsta, logoDiscord, logoFacebook, logoLinkedin;
let RoadmapStartingRocket, RoadmapCity, RoadmapController, RoadmapPlanet, RoadmapMovingRocket;

// Display the loading screen initially
document.getElementById("loadingOverlay").style.display = "flex";
let RoadmapHeight = -21;
if (isMobile()) {
    RoadmapHeight = -29.8;
}
// Wrap your loading function to control the overlay
(async () => {
    try {
        isTransitioningtoscene = true;
        if (!isMobile()) {
            await loadModels(); // Place your model loading code here
        }
        else {
            await loadModels_mobile();
        }
        isTransitioningtoscene = false;
        // Hide the loading overlay with GSAP
        gsap.to("#loadingOverlay", {
            opacity: 0,
            duration: 1,
            onComplete: () => document.getElementById("loadingOverlay").style.display = "none"
        });
    } catch (error) {
        console.error("Error loading model:", error);
    }
})();

async function loadModels() {
    try {
        let landingYOffset = 0;
        if (window.innerHeight < 1000) {
            const heightDiff = 1000 - window.innerHeight;
            landingYOffset = Math.min(heightDiff / 300, 1); // scales down by max 1 unit for screen heights down to 700px
        }
        // Base positions for easy layout adjustment
        const landingBasePosition = new THREE.Vector3(0, -0.7 - landingYOffset, 0);
        const roadmapBasePosition = new THREE.Vector3(0, RoadmapHeight - 1, 0);
        const portraitBasePosition = new THREE.Vector3(-4.15, -14.8, -0.5);

        // LANDING MODELS
        gamersModel = await importGLTFAnimatedModel(GamersModelURL, landingBasePosition.clone().add(new THREE.Vector3(0, 0.2, 0)), new THREE.Vector3(1, 1, 1), gltfLoader);
        scene.add(gamersModel);
        animatedButtons.push(gamersModel);
        const gamerLabel = createLabelForModel(gamersModel,
            'Gamers',
            '',
            'Scegli il tuo prossimo gioco preferito',
            0.15, -1, 0);
        setupHoverSynchronization(gamersModel, gamerLabel.element);

        gameDevsModel = await importGLTFAnimatedModel(GameDevsModelURL, landingBasePosition.clone().add(new THREE.Vector3(-4.2, 0.2, -1)), new THREE.Vector3(1, 1, 0.55), gltfLoader);
        scene.add(gameDevsModel);
        animatedButtons.push(gameDevsModel);
        const gameDevsLabel = createLabelForModel(gameDevsModel,
            'Game Devs',
            '',
            'Ti aiuteremo a dar vita ai tuoi progetti',
            0.3, -1, 1);
        setupHoverSynchronization(gameDevsModel, gameDevsLabel.element);

        investorsModel = await importGLTFAnimatedModel(InvestorsModelURL, landingBasePosition.clone().add(new THREE.Vector3(3.5, 0.2, 0.1)), new THREE.Vector3(1, 1, 1), gltfLoader);
        scene.add(investorsModel);
        animatedButtons.push(investorsModel);
        const investorsLabel = createLabelForModel(investorsModel,
            'Investors',
            '',
            'Guadagna dai progetti in cui credi',
            0.4, -1, -0.1);
        setupHoverSynchronization(investorsModel, investorsLabel.element);
        
        // ROADMAP MODELS
        RoadmapStartingRocket = await importGLTFAnimatedModel(RoadmapStartingRocketURL, roadmapBasePosition.clone().add(new THREE.Vector3(-4, -0.2, -0.2)), new THREE.Vector3(0.15, 0.15, 0.1), gltfLoader);
        scene.add(RoadmapStartingRocket);

        RoadmapPlanet = await importGLTFAnimatedModel(RoadmapPlanetURL, roadmapBasePosition.clone().add(new THREE.Vector3(-2, 0, -0.2)), new THREE.Vector3(0.15, 0.15, 0.1), gltfLoader);
        scene.add(RoadmapPlanet);

        RoadmapController = await importGLTFAnimatedModel(RoadmapControllerURL, roadmapBasePosition.clone().add(new THREE.Vector3(0, 0, -0.2)), new THREE.Vector3(0.25, 0.25, 0.2), gltfLoader);
        scene.add(RoadmapController);

        RoadmapCity = await importGLTFAnimatedModel(RoadmapCityURL, roadmapBasePosition.clone().add(new THREE.Vector3(2, 0, -0.2)), new THREE.Vector3(0.2, 0.2, 0.14), gltfLoader);
        scene.add(RoadmapCity);

        RoadmapMovingRocket = await importGLTFAnimatedModel(RoadmapMovingRocketURL, roadmapBasePosition.clone().add(new THREE.Vector3(4, 0, -0.2)), new THREE.Vector3(0.25, 0.25, 0.2), gltfLoader);
        RoadmapMovingRocket.rotation.y = -Math.PI / 8;
        scene.add(RoadmapMovingRocket);

        // PORTRAITS
        const portraits = await importPortraitBase(portraitBasePosition.clone(), new THREE.Vector3(0.65, 0.65, 0.65), cssScene);
        const partners = await importPortraitPartners(portraitBasePosition.clone().add(new THREE.Vector3(1.35, -13.5, 0)), new THREE.Vector3(0.8, 0.8, 0.8), cssScene);

        // Light under portraits
        const light = new THREE.PointLight(0xffffff, 1, 100);
        light.position.copy(portraitBasePosition.clone().add(new THREE.Vector3(0, -1.7, 0)));
        scene.add(light);

    } catch (error) {
        console.error('Error loading model:', error);
    }
}
const mobileOffsetx = -0.2;


async function loadModels_mobile() {
    try {
        let landingYOffset = 0.0;
        if (window.innerHeight < 1000) {
            const heightDiff = 1000 - window.innerHeight;
            landingYOffset = Math.min(heightDiff / 300, 1);
        }
        sphere.position.set(0, -100, 0);
        // Adjusted positions for mobile
        const landingBasePosition = new THREE.Vector3(0, -0.7 - landingYOffset, 0);
        const roadmapBasePosition = new THREE.Vector3(0, RoadmapHeight - 2, 2.5); // Moving roadmap models down
        //calculate the position center of the screen
        const centerScreenPosition = new THREE.Vector3(0, 0, -0.5);
        centerScreenPosition.unproject(camera);
        const portraitBasePosition = new THREE.Vector3(centerScreenPosition.x, -18.3, 2.3); // Adjusted to move down for mobile
        const xOffset = 0.8;
        const SizeMultiplier = 0.7;
        // LANDING MODELS - Adjusted scaling and positions in a narrower triangle formation
        gamersModel = await importGLTFAnimatedModel(GamersModelURL, landingBasePosition.clone().add(new THREE.Vector3(0, -0.2, 0)), new THREE.Vector3(0.8, 0.8, 0.8), gltfLoader);
        scene.add(gamersModel);
        animatedButtons.push(gamersModel);
        const gamerLabel = createLabelForModel(gamersModel,
            'Gamers',
            '',
            'Scegli il tuo prossimo gioco preferito',
            0.1, -0.8, 0);
        setupHoverSynchronization(gamersModel, gamerLabel.element);

        gameDevsModel = await importGLTFAnimatedModel(GameDevsModelURL, landingBasePosition.clone().add(new THREE.Vector3(-1.05, 1.2, -1.5)), new THREE.Vector3(0.6, 0.6, 0.4), gltfLoader);
        scene.add(gameDevsModel);
        animatedButtons.push(gameDevsModel);
        const gameDevsLabel = createLabelForModel(gameDevsModel,
            'Game Devs',
            '',
            'Ti aiuteremo a dar vita ai tuoi progetti',
            0.2, -0.65, 1);

        setupHoverSynchronization(gameDevsModel, gameDevsLabel.element);
        investorsModel = await importGLTFAnimatedModel(InvestorsModelURL, landingBasePosition.clone().add(new THREE.Vector3(1.15, 0.85, -1.5)), new THREE.Vector3(0.8, 0.8, 0.8), gltfLoader);
        scene.add(investorsModel);
        animatedButtons.push(investorsModel);
        const investorsLabel = createLabelForModel(investorsModel,
            'Investors',
            '',
            'Guadagna dai progetti in cui credi',
            -0.2, -0.3, 1);
        setupHoverSynchronization(investorsModel, investorsLabel.element);
        RoadmapStartingRocket = await importGLTFAnimatedModel(
            RoadmapStartingRocketURL,
            new THREE.Vector3(xOffset, RoadmapHeight + 2.5, 1.85),
            new THREE.Vector3(0.075 * SizeMultiplier, 0.075 * SizeMultiplier, 0.05 * SizeMultiplier),
            gltfLoader
        );
        scene.add(RoadmapStartingRocket);

        RoadmapPlanet = await importGLTFAnimatedModel(
            RoadmapPlanetURL,
            new THREE.Vector3(xOffset, RoadmapHeight + 1.25, 1.85),
            new THREE.Vector3(0.075 * SizeMultiplier, 0.075 * SizeMultiplier, 0.05 * SizeMultiplier),
            gltfLoader
        );
        scene.add(RoadmapPlanet);

        RoadmapController = await importGLTFAnimatedModel(
            RoadmapControllerURL,
            new THREE.Vector3(xOffset, RoadmapHeight, 1.85),
            new THREE.Vector3(0.125 * SizeMultiplier, 0.125 * SizeMultiplier, 0.1 * SizeMultiplier),
            gltfLoader
        );
        scene.add(RoadmapController);

        RoadmapCity = await importGLTFAnimatedModel(
            RoadmapCityURL,
            new THREE.Vector3(xOffset, RoadmapHeight - 1.25, 1.85),
            new THREE.Vector3(0.1 * SizeMultiplier, 0.1 * SizeMultiplier, 0.07 * SizeMultiplier),
            gltfLoader
        );
        scene.add(RoadmapCity);

        RoadmapMovingRocket = await importGLTFAnimatedModel(
            RoadmapMovingRocketURL,
            new THREE.Vector3(xOffset, RoadmapHeight - 2.5, 1.85),
            new THREE.Vector3(0.125 * SizeMultiplier, 0.125 * SizeMultiplier, 0.1 * SizeMultiplier),
            gltfLoader
        );
        RoadmapMovingRocket.rotation.y = -Math.PI / 8;
        scene.add(RoadmapMovingRocket);


        // PORTRAITS - Adjusted positions for mobile
        const portraits = await importPortraitBase(new THREE.Vector3(0, -20, 1.3), new THREE.Vector3(0.6, 0.6, 0.6), cssScene);
        const partners = await importPortraitPartners(portraitBasePosition.clone().add(new THREE.Vector3(0, -19.5, 0)), new THREE.Vector3(0.7, 0.7, 0.7), cssScene);

        // Light under portraits - Adjusted position for mobile
        const light = new THREE.PointLight(0xffffff, 1, 100);
        light.position.copy(portraitBasePosition.clone().add(new THREE.Vector3(0, -2.2, 0)));
        scene.add(light);

    } catch (error) {
        console.error('Error loading model:', error);
    }
}
function setupHoverSynchronization(model, labelElement) {
    model.userData.label = labelElement;

    // Model hover logic
    model.onLabelHover = () => {
        if (!model.userData.isHovered) {
            model.userData.isHovered = true;
            model.userData.actions.forEach((action) => {
                if (!action.isRunning()) {
                    action.reset();
                    action.paused = false;
                    action.timeScale = 1;
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                }
            });
        }
    };

    model.onLabelHoverOut = () => {
        if (model.userData.isHovered) {
            model.userData.isHovered = false;
            model.userData.actions.forEach((action) => {
                if (action.isRunning()) {
                    action.paused = false;
                    action.timeScale = -1; // Play animation backward
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                }
            });
        }
    };

    // Label hover logic
    labelElement.addEventListener('mouseenter', () => {
        model.onLabelHover();
        console.log('Label hover activated model animation');
    });

    labelElement.addEventListener('mouseleave', () => {
        model.onLabelHoverOut();
        console.log('Label hover deactivated model animation');
    });
}


// COMMON VARIABLES
const circleTexts = ['Q1', 'Q2', 'Q3', 'Q4', 'Q1'];
const descriptions = [
    'Creazione della community',
    '3 giochi indie validati',
    'Sviluppo dei progetti validati',
    'Lancio della piataforma',
    'Ampliamento dei servizi per sviluppatori',
];

// SETUP BASED ON DEVICE TYPE
if (isMobile()) {
    setupMobileVersion();
} else {
    setupDesktopVersion();
}

function setupDesktopVersion() {
    // DESKTOP ROADMAP PLANE
    const roadmapPlaneGeometry = createRoundedPlane(12, 5, 0.5);
    const roadmapPlane = new THREE.Mesh(roadmapPlaneGeometry, blurryMaterial);
    roadmapPlane.position.set(0, RoadmapHeight, -1);
    scene.add(roadmapPlane);

    // DESKTOP ROADMAP CIRCLES & LINE
    const positions = [
        new THREE.Vector3(-4, RoadmapHeight - 3, -0.5),
        new THREE.Vector3(-2, RoadmapHeight - 3, -0.5),
        new THREE.Vector3(0, RoadmapHeight - 3, -0.5),
        new THREE.Vector3(2, RoadmapHeight - 3, -0.5),
        new THREE.Vector3(4, RoadmapHeight - 3, -0.5),
    ];

    createRoadmapLine('row', positions);
}
function setupMobileVersion() {
    // MOBILE ROADMAP PLANE
    const roadmapPlaneGeometry = createRoundedPlane(0.9, 6, 0.2); // Swapped width and height
    const roadmapPlane = new THREE.Mesh(roadmapPlaneGeometry, blurryMaterial);
    roadmapPlane.position.set(0.7 + mobileOffsetx, RoadmapHeight, 1.75);
    scene.add(roadmapPlane);

    // MOBILE ROADMAP CIRCLES & LINE
    const verticalPositions = [
        new THREE.Vector3(mobileOffsetx, RoadmapHeight + 2.5, 1.75),
        new THREE.Vector3(mobileOffsetx, RoadmapHeight + 1.25, 1.75),
        new THREE.Vector3(mobileOffsetx, RoadmapHeight, 1.75),
        new THREE.Vector3(mobileOffsetx, RoadmapHeight - 1.25, 1.75),
        new THREE.Vector3(mobileOffsetx, RoadmapHeight - 2.5, 1.75),
    ];

    createRoadmapLine('column', verticalPositions);
}

function createRoadmapLine(direction, positions) {
    // Create a container for the line and arrow
    const lineContainer = document.createElement('div');
    lineContainer.className = 'line-container';
    lineContainer.style.flexDirection = direction === 'column' ? 'column' : 'row';

    // Line element
    const lineElement = document.createElement('div');
    lineElement.className = 'gradient-line';
    if (direction === 'column') {
        lineElement.style.height = '1000px'; // Increase height for vertical layout
        lineElement.style.width = '5px'; // Adjust width for the vertical line
    }

    // Arrow element
    const arrowElement = document.createElement('div');
    arrowElement.className = 'arrow';
    if (direction === 'column') {
        arrowElement.style.transform = 'rotate(180deg)'; // Rotate the arrow to point down
    }

    // Append line and arrow to the container
    lineContainer.appendChild(lineElement);
    lineContainer.appendChild(arrowElement);

    // Create CSS3DObject for the line container
    const lineObject = new CSS3DObject(lineContainer);


    cssScene.add(lineObject);
    if (isMobile()) {
        lineObject.position.set(.6 + mobileOffsetx, RoadmapHeight - .325, 1.75);
        lineObject.scale.set(0.006, 0.006, 0.006); // Adjust scale as needed
    }
    else {
        lineObject.position.set(0, RoadmapHeight - 3, -0.5);
        lineObject.scale.set(0.01, 0.01, 0.01); // Adjust scale as needed
    }
    // Create circles with text and add to scene
    for (let i = 0; i < positions.length; i++) {
        const circleObject = createCircleWithText(
            positions[i],
            circleTexts[i],
            descriptions[i]
        );
        if (isMobile()) {
            circleObject.scale.set(0.006, 0.006, 0.006); // Adjust scale as needed
        }
        else {
            circleObject.scale.set(0.01, 0.01, 0.01); // Adjust scale as needed
        }
        cssScene.add(circleObject);
    }
}

function createCircleWithText(position, circleText, descriptionText) {
    // Container for the circle and description
    const container = document.createElement('div');
    container.className = 'circle-container';

    // Circle element
    const circle = document.createElement('div');
    circle.className = 'circle';
    circle.innerText = circleText;

    // Description text
    const description = document.createElement('div');
    description.className = 'description';
    description.innerText = descriptionText;

    // Append elements
    container.appendChild(circle);
    container.appendChild(description);

    // Create CSS3DObject
    const cssObject = new CSS3DObject(container);
    cssObject.position.copy(position);
    cssObject.scale.set(0.006, 0.006, 0.006); // Adjust scale as needed
    return cssObject;
}




function createLabelForModel(object_, textContent, SubheadingContent, ParagraphContent, offsetX = 0, offsetY = 0, offsetZ = 0) {
    // Create the label element
    const labelElement = document.createElement('div');
    labelElement.className = 'label';
    labelElement.innerHTML = `
        <h1>${textContent}</h1>
        <h2>${SubheadingContent}</h2>
        <p>${ParagraphContent}</p>
    `;

    // Convert the label into a CSS3DObject
    const labelObject = new CSS3DObject(labelElement);

    labelObject.position.copy(new THREE.Vector3(object_.position.x + offsetX, object_.position.y + offsetY, object_.position.z + offsetZ));
    labelObject.scale.set(0.01, 0.01, 0.01); // Adjust scale as needed
    cssScene.add(labelObject);
    return labelObject;
}
const light = new THREE.PointLight(0xffffff, 1, 100);
light.position.set(0, -27, 0);
scene.add(light);




//primitives
planeMesh.receiveShadow = true;
planeMesh.rotation.x = Math.PI / 2;
planeMesh.position.y = -14;

//particles
const parameters = {}
parameters.count = 1000
parameters.size = 1
const positionsArray = [];
// Create the custom ShaderMaterial
let galaxyMaterial;
galaxyMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
    },
    vertexShader: `
            // Add 'galaxyOffset' as an attribute
            attribute vec3 galaxyOffset;

            uniform vec3 uMouse;
            uniform float uTime;

            void main() {
                // Calculate the world position by adding the galaxy offset
                vec3 worldPosition = position + galaxyOffset;

                // Use worldPosition for distance and direction calculations
                float dist = distance(worldPosition, uMouse);
                float displacement = 100.0 * exp(-dist * 0.01);
                vec3 direction = normalize(worldPosition - uMouse);
                vec3 newPosition = worldPosition + direction * displacement;

                vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
                gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
    fragmentShader: `
            void main() {
                float d = length(gl_PointCoord - vec2(0.5));
                if (d > 0.5) discard;
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.5);
            }
        `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});
const generateGalaxy = (size, offset, particleCount) => {
    /**
     * Geometry
     */
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        // Generate positions without adding the offset
        positions[i3] = (Math.random() - 0.5) * size.x; // x
        positions[i3 + 1] = (Math.random() - 0.5) * size.y; // y
        positions[i3 + 2] = (Math.random() - 0.5) * size.z; // z

        // Set the galaxy offset for each particle
        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyMaterial);
    scene.add(points);
};

generateGalaxy(new THREE.Vector3(100, 20, 40), new THREE.Vector3(0, 5, -20), 600);
generateGalaxy(new THREE.Vector3(300, 110, 40), new THREE.Vector3(0, -10, -90), 1000);
generateGalaxy(new THREE.Vector3(40, 80, 90), new THREE.Vector3(70, 0, 0), 1500);
generateGalaxy(new THREE.Vector3(40, 80, 90), new THREE.Vector3(-70, 0, 0), 1500);



//animation
let step = 0;
let previousHoveredObject = null;
const mouseWorldPosition = new THREE.Vector3();
function animate() {
    if (isActiveScene) {
        let deltaTime = clock.getDelta();
        let elapsedTime = clock.getElapsedTime();

        // Camera animation
        if (isAnimatingCamera) {
            let t = (elapsedTime - cameraAnimationStartTime) / cameraAnimationDuration;
            if (t >= 1) {
                t = 1;
                isAnimatingCamera = false;
            }

            // Interpolate camera position
            camera.position.lerpVectors(cameraStartPosition, cameraEndPosition, t);

            // Interpolate camera rotation
            camera.quaternion.slerpQuaternions(cameraStartQuaternion, cameraEndQuaternion, t);
        }

        // Existing cube animation
        //gamersModel.position.y = Math.abs(Math.sin(step)) / 4;
        step += 0.01;
        //gameDevsModel.rotation.y += 0.006;
        //investorsModel.rotation.y += 0.008;
        // Update the mouse position in world coordinates
        const vector = new THREE.Vector3(mouseposition.x, mouseposition.y, 0.5); // z = 0.5 for middle of the frustum
        vector.unproject(camera);
        mouseWorldPosition.copy(vector);

        // Update the shader uniforms
        if (galaxyMaterial) {
            galaxyMaterial.uniforms.uMouse.value.copy(mouseWorldPosition);
            galaxyMaterial.uniforms.uTime.value = elapsedTime;
        }
        //make all roadmap models rotate on the y axis
        if (RoadmapStartingRocket && RoadmapCity && RoadmapController && RoadmapPlanet && RoadmapMovingRocket) {

            RoadmapStartingRocket.children.rotation = new THREE.Euler(0, elapsedTime, 0);
            RoadmapCity.rotation = new THREE.Euler(0, elapsedTime, 0);
            RoadmapController.rotation = new THREE.Euler(0, elapsedTime, 0);
            RoadmapPlanet.rotation = new THREE.Euler(0, elapsedTime, 0);
            RoadmapMovingRocket.rotation = new THREE.Euler(0, elapsedTime, 0);
        }



        animateModelsOnHover(animatedButtons, deltaTime, raycaster, mouseposition, camera);
        composer.render(deltaTime);
        cssRenderer.render(cssScene, camera);
    }
}

export function animateCameraToBottom() {
    if (isAnimatingCamera || isTransitioningtoscene) return;

    isAnimatingCamera = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation
    cameraEndPosition.set(cameraStartPosition.x, -7, cameraStartPosition.z); // Move down and closer
    //Create a fake object to calculate correct lookAt -TODO


    camera.lookAt(0, 1.5, 0); // Adjust to look at a lower point
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}
export function animateCameraToTop() {
    if (isAnimatingCamera) return;

    isAnimatingCamera = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation (original position)
    cameraEndPosition.set(cameraStartPosition.x, 1.5, cameraStartPosition.z); // Original position
    camera.lookAt(0, -7.5, 0); // Original lookAt
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}

//events
window.addEventListener('resize', () => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    cssRenderer.setSize(window.innerWidth, window.innerHeight);
    if (isMobile()) return;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    composer.setSize(window.innerWidth, window.innerHeight);
    cssRenderer.render(cssScene, camera);
    renderer.render(scene, camera);

});
const mouseposition = new THREE.Vector2();

window.addEventListener('mousemove', function (e) {
    mouseposition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouseposition.y = - (e.clientY / window.innerHeight) * 2 + 1; // Corrected calculation
});

//when the page reload set the scrolling to 0
window.addEventListener('load', () => {
    //reset all the css animations
    smoothScrollToTop();
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 50); // Adjust the delay as needed
});
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
};

window.addEventListener('click', onMouseClick, false);
window.onpopstate = function (event) {
    if (event.state && event.state.page) {
        initiateSceneTransition(event.state.page);
    }
};




//raycasting
const raycaster = new THREE.Raycaster();
raycaster.setFromCamera(mouseposition, camera);
const intersects = raycaster.intersectObjects(scene.children);

function onMouseClick(event) {
    // Update mouseposition
    if (isActiveScene) {
        mouseposition.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouseposition.y = - (event.clientY / window.innerHeight) * 2 + 1;

        // Perform raycasting
        raycaster.setFromCamera(mouseposition, camera);
        if (gameDevsModel && gamersModel && investorsModel) {
            const intersects = raycaster.intersectObjects([gamersModel, gameDevsModel, investorsModel], intersect = true);
            // Traverse up to find the parent model
            if (intersects.length > 0) {  ///////CHANGED FOR DEBUG
                let object = intersects[0].object;
                //make the mouse a pointer
                document.body.style.cursor = 'pointer';
                while (object.parent && object.parent !== scene) {
                    object = object.parent;
                }
                let page = null;
                if (object === investorsModel) {
                    page = 'investors';
                } else if (object === gamersModel) {
                    page = 'gamers';
                } else if (object === gameDevsModel) {
                    page = 'gamedevs';
                }
                if (page) {

                    initiateSceneTransition(page);
                }
            }
        }

    }
}

let timeoutTimer = 0;
//page and scene transition
export function initiateSceneTransition(page) {
    if (isTransitioningtoscene || isAnimatingCamera) return;
    isTransitioningtoscene = true;
    // Start transition animation
    if (page === 'investors') {
        timeoutTimer = 1000;
        transitionToInvestors();
    } else if (page === 'gamers') {
        timeoutTimer = 2500;
        transitionToGamers();
    } else if (page === 'gamedevs') {
        timeoutTimer = 1500;
        transitionToGameDevs();
    }
    // Await the timeout before loading the new page content and scene
    setTimeout(async () => {
        isActiveScene = false; // Prevent interactions during transition

        // Clear the scene carefully
        clearThree(scene);
        clearThree(cssScene);
        // Load new content and scene
        await loadPageContent(page);
        await loadPageScene(page);
        isTransitioningtoscene = false;
    }, timeoutTimer);
}



window.initiateSceneTransition_navbar = initiateSceneTransition;

function transitionToInvestors() {
    if (isActiveScene) {
        // Rotate the camera 90 degrees on the Y-axis over 1 second
        gsap.to(camera.rotation, {
            y: camera.rotation.y - Math.PI / 2,
            duration: 1,
            ease: 'power2.inOut'
        });

        // Move the camera rapidly towards the positive Y direction over 1 second
        gsap.to(camera.position, {
            x: camera.position.x + 50, // Adjust the value as needed for your scene scale
            duration: 1,
            ease: 'power2.in'
        });

        // Optional: Add motion blur effect (requires post-processing setup)
        // You can skip this if not set up
        transitionBackgroundToColorWithOffset(scene, gradientT, 0x030511, 2);

        // After 1 second, fade out the scene by reducing opacity to 0 over 0.5 seconds
        gsap.to('canvas', {
            opacity: 0,
            duration: 0.25,
            delay: 0.75 // Starts after the camera animation completes
        });
    }
}

function transitionToGamers() {
    if (isActiveScene) {
        // Move the object back and up over 2.5 seconds
        gsap.to(gamersModel.position, {
            z: gamersModel.position.z - 20, // Move back
            y: gamersModel.position.y + 10, // Move up
            duration: 2.5,
            ease: 'power2.inOut'
        });

        // Move the camera up towards the object but at a slower pace over 2.5 seconds
        gsap.to(camera.position, {
            y: camera.position.y + 5, // Adjust as needed
            duration: 2.5,
            ease: 'power2.inOut',
            onUpdate: function () {
                // Keep the camera looking at the object
                camera.lookAt(gamersModel.position);
            }
        });
        transitionBackgroundToColorWithOffset(scene, gradientT, 0x030511, 2);

        // After 2.5 seconds, fade out the scene by reducing opacity to 0 over 0.5 seconds
        gsap.to('canvas', {
            opacity: 0,
            duration: 0.5,
            delay: 2
        });
    }
}

function transitionToGameDevs() {
    if (isActiveScene) {
        // Rotate the camera to the left over 1.5 seconds
        gsap.to(camera.rotation, {
            y: camera.rotation.y + Math.PI / 2,
            duration: 1.5,
            ease: 'power2.inOut'
        });

        // Move the camera to the left over 1.5 seconds
        gsap.to(camera.position, {
            x: camera.position.x - 50, // Adjust the value as needed
            duration: 1.5,
            ease: 'power2.inOut'
        });

        // Optional: Spawn an effect or object to make it look cool
        // Example: Create a light flash or particle effect here
        transitionBackgroundToColorWithOffset(scene, gradientT, 0x030511, 2);
        // After 1.5 seconds, fade out the scene by reducing opacity to 0 over 0.5 seconds
        gsap.to('canvas', {
            opacity: 0,
            duration: 0.5,
            delay: 1
        });
    }
}
function transitionBackgroundToColorWithOffset(scene, texture, colorEnd, duration = 2) {
    // Step 1: Ensure the background is the texture initially
    scene.background = texture;
    texture.offset = new THREE.Vector2(0, 0); // Start offset at (0, 0)

    // Step 2: Animate the texture offset upwards
    gsap.to(texture.offset, {
        y: 0.5, // Move the texture up by 50%
        duration: duration / 2,
        ease: "power2.inOut",
        onUpdate: () => renderer.render(scene, camera),
        onComplete: () => {
            // Step 3: Switch to color background
            scene.background = new THREE.Color(colorEnd);
        }
    });
}


async function loadPageScene(page) {
    // Remove existing scene objects
    try {
        let Module;
        if (page === 'investors') {
            Module = await import('./investors.js');
            SetIsScrollable(Module, 1);
        } else if (page === 'gamers') {
            SetIsScrollable(Module, 2);
            Module = await import('./gamers.js');
        } else if (page === 'gamedevs') {
            Module = await import('./gamedevs.js');
            SetIsScrollable(Module, 3);
        }
        if (typeof Module.initNewScene === 'function') {

            await Module.initNewScene(scene, renderer, camera, cssRenderer, cssScene);
            initScroll();
        } else {
            console.error('initNewScene function is not exported in the scene module.');
        }
    } catch (error) {
        console.error('Error loading scene module:', error);
    }
}

// Utility function to dispose of all objects
function disposeMaterial(material) {
    // Check all properties of the material for disposable resources
    for (const prop in material) {
        if (!material.hasOwnProperty(prop)) continue;
        const value = material[prop];

        // Textures or other disposable objects typically have a dispose() method
        if (value && typeof value.dispose === 'function') {
            value.dispose();
        }
    }

    // Finally, dispose of the material itself
    material.dispose();
}

function disposeNode(node) {
    // Dispose geometry
    if (node.geometry) {
        node.geometry.dispose();
        node.geometry = null;
    }

    // Dispose materials (can be a single material or an array)
    if (node.material) {
        if (Array.isArray(node.material)) {
            for (let i = 0; i < node.material.length; i++) {
                disposeMaterial(node.material[i]);
            }
        } else {
            disposeMaterial(node.material);
        }
        node.material = null;
    }
}

function clearThree(obj) {
    // Recursively remove and dispose children first
    for (let i = obj.children.length - 1; i >= 0; i--) {
        const child = obj.children[i];
        clearThree(child);
        obj.remove(child);
    }

    // Dispose the current object's geometry and materials
    disposeNode(obj);
}
async function loadPageContent(page) {
    try {
        const response = await fetch(`${page}.html`);
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const html = await response.text();
        document.getElementById('mainContainer').innerHTML = html;

        // If you want a slight delay after loading:
        await new Promise(resolve => setTimeout(resolve, 100));
    } catch (error) {
        console.error(`Error loading ${page}.html:`, error);
        document.getElementById('mainContainer').innerHTML = `<p>Error loading content.</p>`;
    }
}


//lighting and shadows
const ambientLight = new THREE.AmbientLight(0x333333, 0.4);
const directionalLight = new THREE.DirectionalLight(0xffffff, 3.5);
directionalLight.shadow.bias = -0.001;
directionalLight.position.set(9, 10, 3);
directionalLight.rotation.x = -Math.PI;
const directionalLight3 = new THREE.PointLight(0xffffff, 60, 200);
directionalLight3.shadow.bias = -0.001;
directionalLight3.position.set(0, -4, 0);
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 5);
directionalLight2.position.set(-9, 10, 3);
directionalLight2.rotation.x = Math.PI / 2;
//add to scene and rendering
renderer.setAnimationLoop(animate);
scene.add(directionalLight);
scene.add(ambientLight);
renderer.render(scene, camera);


