import * as THREE from 'three';
import { RoundedBoxGeometry } from './RoundedBoxGeometry';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { isMobile } from './pages';


export const socialDiscordURL = new URL('../assets/models/socialButton.glb', import.meta.url);
export const GameDevsModelURL = new URL('../assets/models/GameDevsModel.glb', import.meta.url);
export const GamersModelURL = new URL('../assets/models/Controller.glb', import.meta.url);
export const InvestorsModelURL = new URL('../assets/models/InvestorsModel.glb', import.meta.url);
export const StonksModelURL = new URL('../assets/models/StonksGraph.glb', import.meta.url);
export const RoadmapMovingRocketURL = new URL('../assets/models/MovingRocket.glb', import.meta.url);
export const RoadmapCityURL = new URL('../assets/models/RoadmapCity.glb', import.meta.url);
export const RoadmapControllerURL = new URL('../assets/models/RoadmapController.glb', import.meta.url);
export const RoadmapPlanetURL = new URL('../assets/models/RoadmapPlanet.glb', import.meta.url);
export const RoadmapStartingRocketURL = new URL('../assets/models/StartingRocket.glb', import.meta.url);

export const GAMEDEVS_BudgetModelURL = new URL('../assets/models/Budget.glb', import.meta.url);
export const GAMEDEVS_RocketModelURL = new URL('../assets/models/GameDevRocket.glb', import.meta.url);
export const GAMEDEVS_IdeaModelURL = new URL('../assets/models/Idea.glb', import.meta.url);
export const GAMEDEVS_LegalModelURL = new URL('../assets/models/Legal.glb', import.meta.url);
export const GAMEDEVS_MarketingModelURL = new URL('../assets/models/Marketing.glb', import.meta.url);
export const GAMEDEVS_MentorsModelURL = new URL('../assets/models/Mentors.glb', import.meta.url);
export const GAMEDEVS_NetworkModelURL = new URL('../assets/models/GamedevNetwork.glb', import.meta.url);
export const INVESTORS_Protection = new URL('../assets/models/Protection_model.glb', import.meta.url);
export const INVESTORS_Crowdfunding = new URL('../assets/models/Crowdfunding_model.glb', import.meta.url);
export const INVESTORS_Videogames = new URL('../assets/models/Videogames_model.glb', import.meta.url);

export const GamersRocketURL = new URL('../assets/models/GamersRocket.glb', import.meta.url);
export const GAMERS_CommunityURL = new URL('../assets/models/CommunityModel.glb', import.meta.url);
export const GAMERS_GamerDevModelURL = new URL('../assets/models/GamerDevModel.glb', import.meta.url);


export function importSocialDiscord(position, scale, gltfLoader) {
    return new Promise((resolve, reject) => {

        gltfLoader.load(
            socialDiscordURL.href,
            function (gltf) {
                const socialDiscord = gltf.scene;
                socialDiscord.scale.set(scale.x, scale.y, scale.z);
                socialDiscord.position.set(position.x, position.y, position.z);
                socialDiscord.traverse(function (node) {
                    if (node.isMesh) {
                        node.castShadow = true;
                        node.receiveShadow = true;

                    }
                });

                // Set up AnimationMixer and actions
                const mixer = new THREE.AnimationMixer(socialDiscord);
                const clips = gltf.animations;

                if (clips.length > 0) {
                    const clip = clips[0];
                    const action = mixer.clipAction(clip);
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.enabled = true;

                    // Initialize action
                    action.reset();
                    try {
                        action.play();
                    } catch (error) { }
                    action.paused = true; // Start paused

                    // Store in userData
                    socialDiscord.userData = {
                        mixer,
                        action,
                        isHovered: false,
                        wasHovered: false
                    };
                }

                resolve(socialDiscord);
            },
            undefined,
            function (error) {
                console.error(error);
                reject(error);
            }
        );
    });
}
export function importGLTFAnimatedModel(ModelURL, position, scale, gltfLoader) {
    return new Promise((resolve, reject) => {
        gltfLoader.load(
            ModelURL.href,
            function (gltf) {
                const model = gltf.scene;
                model.scale.set(scale.x, scale.y, scale.z);
                model.position.set(position.x, position.y, position.z);

                model.traverse((node) => {
                    if (node.isMesh) {
                        node.castShadow = true;
                        node.receiveShadow = true;

                        // Replace material with MeshToonMaterial, preserving basic properties
                        const oldMaterial = node.material;

                        // Handle array of materials
                        if (Array.isArray(oldMaterial)) {
                            const newMaterials = oldMaterial.map((material) => {
                                return new THREE.MeshToonMaterial({
                                    color: material.color,
                                    map: material.map,
                                    gradientMap: null, // You can use a gradient map texture to control the cel shading effect
                                    opacity: material.opacity,
                                    transparent: material.transparent,
                                    alphaTest: material.alphaTest,
                                    side: material.side,
                                    depthTest: material.depthTest,
                                    depthWrite: material.depthWrite,
                                });
                            });
                            node.material = newMaterials;
                        } else {
                            node.material = new THREE.MeshToonMaterial({
                                color: oldMaterial.color,
                                map: oldMaterial.map,
                                gradientMap: null, // Optional gradient map for more cel-shading effect control
                                opacity: oldMaterial.opacity,
                                transparent: oldMaterial.transparent,
                                alphaTest: oldMaterial.alphaTest,
                                side: oldMaterial.side,
                                depthTest: oldMaterial.depthTest,
                                depthWrite: oldMaterial.depthWrite,
                            });
                        }
                    }
                });

                // Set up AnimationMixer and actions
                const mixer = new THREE.AnimationMixer(model);
                const clips = gltf.animations;
                const actions = [];

                if (clips.length > 0) {
                    // Create an action for each clip
                    clips.forEach((clip) => {
                        const action = mixer.clipAction(clip);
                        action.loop = THREE.LoopOnce;
                        action.clampWhenFinished = true;
                        action.enabled = true;

                        // Initialize action
                        action.reset();
                        if (action.name) {
                            action.play(); // Necessary to initialize
                        }
                        action.paused = true; // Start paused

                        const filteredClip = filterTracksByValidNodes(model, clip);
                    
                        if (filteredClip.tracks.length > 0) {
                            const action = mixer.clipAction(filteredClip);
                            actions.push(action);
                        }
                        
                    });

                    // Store in userData
                    model.userData = {
                        mixer,
                        actions, // Store array of actions
                        isHovered: false,
                        wasHovered: false,
                    };
                }

                resolve(model);
            },
            undefined,
            function (error) {
                console.error(error);
                reject(error);
            }
        );
    });
}


export function importPortraitBase(position, scale, css3DScene) {
    const portraitImageURLs = [
        new URL('../assets/portraitImages/JacopoPiccinelli.jpeg', import.meta.url).href,
        new URL('../assets/portraitImages/MatteoBugatti.jpeg', import.meta.url).href,
        new URL('../assets/portraitImages/AlessandroCaltran.jpeg', import.meta.url).href,
        new URL('../assets/portraitImages/GabrieleBonetto.jpeg', import.meta.url).href,
        new URL('../assets/portraitImages/VincenzoBinetti.jpeg', import.meta.url).href
    ];
    const names = [
        'Jacopo Piccinelli',
        'Matteo Bugatti',
        'Alessandro Caltran',
        'Gabriele Bonetto',
        'Vincenzo Binetti'
    ];
    const roles = [
        'Programming',
        'Communication',
        'CEO',
        'Management',
        'Legal'
    ];

    const portraits = []; // Array to hold portrait CSS3D objects
    let spacing, currentPositionX;

    if (isMobile()) {
        spacing = scale.x + 0.6; // Adjust spacing for mobile
        currentPositionX = position.x - spacing / 2; // Start positioning for two columns
    } else {
        spacing = scale.x + 1.4; // Adjust spacing as needed
        currentPositionX = position.x;
    }

    // Function to create CSS-based portraits
    for (let i = 0; i < portraitImageURLs.length; i++) {
        const imageURL = portraitImageURLs[i];
        const name = names[i];
        const role = roles[i];

        // Create the main portrait container
        const portraitContainer = document.createElement('div');
        portraitContainer.className = 'portrait-container';
        portraitContainer.style.position = 'absolute';
        portraitContainer.style.transform = `translate3d(-50%, -50%, 0)`;

        // Create the image wrapper (circle mask)
        const imgWrapper = document.createElement('div');
        imgWrapper.className = 'portrait-circle';
        imgWrapper.style.backgroundImage = `url(${imageURL})`;
        portraitContainer.appendChild(imgWrapper);

        // Create the label
        const label = document.createElement('div');
        label.className = 'boxedTitle';
        const p = document.createElement('p');
        p.innerHTML = name;
        label.appendChild(p);
        const h2 = document.createElement('h2');
        h2.innerHTML = role;
        label.appendChild(h2);

        portraitContainer.appendChild(label);

        // Create a CSS3DObject
        const cssObject = new CSS3DObject(portraitContainer);

        // Position the label under the portrait
        if (isMobile()) {
            cssObject.position.set(
                currentPositionX,
                position.y - 1.2,
                position.z
            );

            // Update the x position for the next portrait
            if ((i + 1) % 2 === 0) {
                currentPositionX = position.x - spacing / 2; // Reset x position for new row
                position.y -= 1.6; // Move down for the next row
            } else {
                currentPositionX += spacing;
            }

            // Center the last portrait if odd number of portraits
            if (i === portraitImageURLs.length - 1 && portraitImageURLs.length % 2 !== 0) {
                cssObject.position.set(
                    position.x,
                    position.y - 1.3,
                    position.z
                );
            }
        } else {
            cssObject.position.set(
                currentPositionX,
                position.y - 1,
                position.z
            );

            // Update the x position for the next portrait
            currentPositionX += spacing;
        }

        // Scale the CSS3D object
        if (isMobile()) {
            cssObject.scale.set(0.004, 0.004, 0.004); // Smaller scale for mobile
        } else {
            cssObject.scale.set(0.007, 0.007, 0.007); // Default scale for desktop
        }

        // Add the label to the CSS3D scene
        css3DScene.add(cssObject);

        // Add to the array
        portraits.push(cssObject);
    }

    return Promise.resolve(portraits);
}


export function importPortraitPartners(position, scale, css3DScene) {
    const portraitImageURLs = [
        new URL('../assets/partnersLogos/Adacta.webp', import.meta.url).href,
        new URL('../assets/partnersLogos/Advant.png', import.meta.url).href,
        new URL('../assets/partnersLogos/InfiniteOfficine.png', import.meta.url).href
    ];

    const portraits = []; // Array to hold portrait CSS3D objects
    const spacing = scale.x + 2; // Adjust spacing as needed
    let currentPositionX = position.x;
    let currentPositionY = position.y;


    // Function to create CSS-based portraits
    for (let i = 0; i < portraitImageURLs.length; i++) {
        const imageURL = portraitImageURLs[i];

        // Create the main portrait container
        const portraitContainer = document.createElement('div');
        portraitContainer.className = 'portrait-container';
        portraitContainer.style.width = '200px'; // Set width
        portraitContainer.style.height = '200px'; // Set height
        portraitContainer.style.position = 'absolute';
        portraitContainer.style.transform = `translate3d(${currentPositionX}px, ${currentPositionY}px, ${position.z}px)`;

        // Create the image wrapper
        const imgWrapper = document.createElement('div');
        imgWrapper.className = 'portrait-image';
        imgWrapper.style.width = '100%';
        imgWrapper.style.height = '100%';
        imgWrapper.style.backgroundImage = `url(${imageURL})`;
        imgWrapper.style.backgroundSize = 'contain'; // Make sure the image fits the container
        imgWrapper.style.backgroundPosition = 'center';
        imgWrapper.style.backgroundRepeat = 'no-repeat'; // Ensure no repetition of the image
        portraitContainer.appendChild(imgWrapper);

        // Create a CSS3DObject
        const cssObject = new CSS3DObject(portraitContainer);

        // Position the portrait
        cssObject.position.set(
            currentPositionX,
            currentPositionY,
            position.z
        );

        // Update the position for the next portrait based on device type
        if (isMobile()) {
            // Scale the CSS3D object
            if (i !== 2) {
                cssObject.scale.set(0.007, 0.007, 0.007);
            } else {
                cssObject.scale.set(0.006, 0.006, 0.006);
            }
            currentPositionY += spacing/3; // Stack vertically on mobile
        } else {
            
            // Scale the CSS3D object
            if (i !== 2) {
                cssObject.scale.set(0.01, 0.01, 0.01);
            } else {
                cssObject.scale.set(0.009, 0.009, 0.009);
            }
            currentPositionX += spacing; // Align horizontally on desktop
        }

        // Add the portrait to the CSS3D scene
        css3DScene.add(cssObject);

        // Add to the array
        portraits.push(cssObject);
    }

    return Promise.resolve(portraits);
}

export function importBlurryGlass(size, position, scene) {
    return new Promise((resolve, reject) => {
        try {
            const geometry = new RoundedBoxGeometry(size.x, size.y, size.z, 5, 0.4);
            const materialOptions = {
                transmission: 1,
                roughness: 0.7,
                emissive: 0xffffff,
                emissiveIntensity: 0.02
            };
            const material = new THREE.MeshPhysicalMaterial(materialOptions);
            const cubeMesh = new THREE.Mesh(geometry, material);
            cubeMesh.position.set(position.x, position.y, position.z);
            scene.add(cubeMesh);
            resolve(cubeMesh);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}
export function importBlurryGlassGradient(size, position, scene) {
    return new Promise((resolve, reject) => {
        try {
            const geometry = new RoundedBoxGeometry(size.x, size.y, size.z, 5, 0.4);
            const materialOptions = {
                transmission: 1,
                roughness: 0.5,
                emissive: 0xffffff,
                emissiveIntensity: 0.02
            };
            const material = new THREE.MeshPhysicalMaterial(materialOptions);
            const cubeMesh = new THREE.Mesh(geometry, material);
            cubeMesh.position.set(position.x, position.y, position.z);
            scene.add(cubeMesh);
            resolve(cubeMesh);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}
// A helper function to filter out invalid tracks
function filterTracksByValidNodes(root, clip) {
    const validTracks = [];
    for (const track of clip.tracks) {
        const binding = THREE.PropertyBinding.create(root, track.name);
        if (binding.node !== null && binding.node !== undefined) {
            validTracks.push(track);
        }
    }
    return new THREE.AnimationClip(clip.name, clip.duration, validTracks);
}

export function animateModelsOnHover(animatedButtons, deltaTime, raycaster, mouseposition, camera) {
    // Update mixers
    for (const object of animatedButtons) {
        if (object.userData.mixer) {
            object.userData.mixer.update(deltaTime);
        }
    }

    // Raycasting to detect hover
    raycaster.setFromCamera(mouseposition, camera);
    const intersects = raycaster.intersectObjects(animatedButtons, true); // Recursive check

    // Reset hover state
    for (const object of animatedButtons) {
        object.userData.isHovered = false;
    }

    // Set hover state for intersected objects
    let interactiveObjectHovered = false;
    for (const intersect of intersects) {
        let intersectedObject = intersect.object;
        // Traverse up to find an ancestor in animatedButtons
        while (intersectedObject) {
            if (animatedButtons.includes(intersectedObject)) {
                intersectedObject.userData.isHovered = true;
                // Make the cursor a pointer when hovering over an interactive object
                if (intersectedObject.userData.label) {
                    document.body.style.cursor = 'pointer';
                    interactiveObjectHovered = true;
                }
                break; // Exit the loop once found
            }
            intersectedObject = intersectedObject.parent;
        }
    }

    // Reset cursor if no interactive object is hovered
    if (!interactiveObjectHovered) {
        document.body.style.cursor = 'default';
    }

    // Control animations based on hover state changes
    for (const object of animatedButtons) {
        const { actions, isHovered, wasHovered } = object.userData;

        // Set up the event listener if not already set
        if (!object.userData.listenerAdded) {
            const mixer = object.userData.mixer;
            if (!mixer) return;
            mixer.addEventListener('finished', (event) => {
                // Check if the action belongs to this object
                if (actions.includes(event.action)) {
                    if (object.userData.shouldPlayForward) {
                        object.userData.shouldPlayForward = false;
                        // Start playing forward
                        const action = event.action;
                        action.reset();
                        action.paused = false;
                        action.timeScale = 1;
                        action.loop = THREE.LoopOnce;
                        action.clampWhenFinished = true;
                        action.play();
                    } else if (object.userData.shouldPlayBackward) {
                        object.userData.shouldPlayBackward = false;
                        // Start playing backward
                        const action = event.action;
                        action.reset();
                        action.paused = false;
                        action.timeScale = -1;
                        action.time = action.getClip().duration;
                        action.loop = THREE.LoopOnce;
                        action.clampWhenFinished = true;
                        action.play();
                    }
                }
            });

            object.userData.listenerAdded = true;
        }

        if (isHovered && !wasHovered) {
            // Hover started
            if (object.userData.label) object.userData.label.classList.add('hovered');

            actions.forEach((action) => {
                if (!action.isRunning()) {
                    // Start playing forward
                    action.reset();
                    action.paused = false;
                    action.timeScale = 1;
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                } else if (action.timeScale < 0) {
                    // Animation is playing backward; queue forward play
                    object.userData.shouldPlayForward = true;
                }
                // If already playing forward, do nothing
            });
        } else if (!isHovered && wasHovered) {
            // Hover ended
            if (object.userData.label) object.userData.label.classList.remove('hovered');

            actions.forEach((action) => {
                if (!action.isRunning()) {
                    // Start playing backward
                    action.reset();
                    action.paused = false;
                    action.timeScale = -1;
                    action.time = action.getClip().duration;
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                } else if (action.timeScale > 0) {
                    // Animation is playing forward; queue backward play
                    object.userData.shouldPlayBackward = true;
                }
                // If already playing backward, do nothing
            });
        }

        // Update wasHovered
        object.userData.wasHovered = isHovered;
    }
}
