import * as THREE from 'three';
import { resetCameraPosition, isMobile } from './pages';
import { createPortalPlane } from './portals';
import { importBlurryGlass, importBlurryGlassGradient, importGLTFAnimatedModel, animateModelsOnHover, GAMERS_CommunityURL, GAMERS_GamerDevModelURL } from './importModels';
import { GamersRocketURL } from './importModels';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { gsap } from 'gsap';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { create } from '@splidejs/splide/src/js/utils';
import Lenis from 'lenis';

let renderer, scene, camera, cssRenderer, cssScene;

const parameters = {}
export let isAnimatingCamera_gamers = false;
parameters.size = 1
let galaxyMaterial;
let isTop;
const positionsArray = [];
const clock = new THREE.Clock();
const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dracoLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dracoLoader);
const loader = new THREE.TextureLoader();

const animatedButtons = [];
// Create the custom ShaderMaterial
galaxyMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
    },
    vertexShader: `
            // Add 'galaxyOffset' as an attribute
            attribute vec3 galaxyOffset;

            uniform vec3 uMouse;
            uniform float uTime;

            void main() {
                // Calculate the world position by adding the galaxy offset
                vec3 worldPosition = position + galaxyOffset;

                // Use worldPosition for distance and direction calculations
                float dist = distance(worldPosition, uMouse);
                float displacement = 100.0 * exp(-dist * 0.01);
                vec3 direction = normalize(worldPosition - uMouse);
                vec3 newPosition = worldPosition + direction * displacement;

                vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
                gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
    fragmentShader: `
            void main() {
                float d = length(gl_PointCoord - vec2(0.5));
                if (d > 0.5) discard;
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.5);
            }
        `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});

const generateGalaxy = (size, offset, particleCount) => {
    /**
     * Geometry
     */
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        // Generate positions without adding the offset
        positions[i3] = (Math.random() - 0.5) * size.x; // x
        positions[i3 + 1] = (Math.random() - 0.5) * size.y; // y
        positions[i3 + 2] = (Math.random() - 0.5) * size.z; // z

        // Set the galaxy offset for each particle
        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyMaterial);
    scene.add(points);
};
let elapsedTime;
// INITIALIZATION
export async function initNewScene(FromScene, FromRenderer, FromCamera, FromCssRenderer, FromCssScene) {
    fadeInCanavas();
    await new Promise((resolve) => { setTimeout(resolve, 500); });
    resetCameraPosition(FromCamera);
    elapsedTime = 0;

    // Your scene setup code
    scene = FromScene;
    renderer = FromRenderer;
    camera = FromCamera;
    cssRenderer = FromCssRenderer;
    cssScene = FromCssScene;
    camera.rotation.x = -0.1;
    // Add the stars to the scene
    generateGalaxy(new THREE.Vector3(100, 100, 100), new THREE.Vector3(0, 0, 0), 1500)
    // Add lights
    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight1.position.set(1, 1, 1).normalize();
    scene.add(directionalLight1);
    const PointLight = new THREE.PointLight(0xffffff, 15, 100);
    PointLight.position.set(2, 1, 3);
    scene.add(PointLight);
    loadTopModelsAndCards()


    renderer.setAnimationLoop(animate);
}
function createCSS3DCard(htmlContent, position, className) {
    // Create a div for the card
    const element = document.createElement('div');
    element.innerHTML = htmlContent;
    element.className = className;

    // Create CSS3DObject
    const css3DObject = new CSS3DObject(element);

    // Set position
    css3DObject.position.set(position.x, position.y, position.z);

    return css3DObject;
}
let rocketModel1, rocketModel2, rocketModel3;
let card1, card2, card3;
let topModelsLoaded = false;

/**
 * This will load the top models and their resources into memory.
 */
async function loadTopModelsAndCards() {
    if (topModelsLoaded) return; // Already loaded

    let pos1, pos2, pos3, scale1, scale2, scale3, offset1, offset2, offset3;

    if (isMobile()) {
        pos1 = new THREE.Vector3(.8, 0.2, -0.2);
        pos2 = new THREE.Vector3(-.8, 1.3, -0.2);
        pos3 = new THREE.Vector3(-.8, -1, -0.2);

        offset1 = new THREE.Vector3(-1.5, 0.3, 0.2);
        offset2 = new THREE.Vector3(1.5, 0.2, 0.2);
        offset3 = new THREE.Vector3(1.5, 0.5, 0.2);

        scale1 = new THREE.Vector3(0.25, 0.25, 0.25);
        scale2 = new THREE.Vector3(0.15, 0.15, 0.15);
        scale3 = new THREE.Vector3(0.05, 0.05, 0.05);
    }
    else {
        pos1 = new THREE.Vector3(-.25, -0.2, -0.2);
        pos2 = new THREE.Vector3(-4, 0.5, -0.2);
        pos3 = new THREE.Vector3(3.3, -0.15, -0.2);

        offset1 = new THREE.Vector3(.25, -.6, 0);
        offset2 = new THREE.Vector3(0, -1.3, 0);
        offset3 = new THREE.Vector3(0.7, -.65, 0);

        scale1 = new THREE.Vector3(0.5, 0.5, 0.5);
        scale2 = new THREE.Vector3(0.4, 0.4, 0.4);
        scale3 = new THREE.Vector3(0.1, 0.1, 0.1);
    }
    rocketModel3 = await importGLTFAnimatedModel(GamersRocketURL, pos3, scale3, gltfLoader);
    scene.add(rocketModel3);
    animatedButtons.push(rocketModel3); // used for animating the models on hover

    rocketModel2 = await importGLTFAnimatedModel(GAMERS_CommunityURL, pos2, scale2, gltfLoader);
    scene.add(rocketModel2);
    animatedButtons.push(rocketModel2); // used for animating the models on hover

    rocketModel1 = await importGLTFAnimatedModel(GAMERS_GamerDevModelURL, pos1, scale1, gltfLoader);
    scene.add(rocketModel1);
    animatedButtons.push(rocketModel1); // used for animating the models on hover



    // Add css 3D object for the models - HTML for the cards
    let card1HTML, card2HTML, card3HTML;
    if (isMobile()) {
        card1HTML = `
                <div class="GamersLandingCard heading4" style="width:500px">
                    <h2>Diventa parte dello sviluppo</h2>
                </div>`;
        card2HTML = `
                <div class="GamersLandingCard heading4" style="width:500px">
                    <h2>Unisciti alla community e investi</h2>
                </div>`;
        card3HTML = `
                <div class="GamersLandingCard heading4" style="width:500px">
                    <h2>Guadagna dai giochi che supporti</h2>
                </div>`;
    } else {
        card1HTML = `
                <div class="GamersLandingCard heading4">
                    <h2>Diventa parte dello sviluppo</h2>
                </div>`;
        card2HTML = `
                <div class="GamersLandingCard heading4">
                    <h2>Unisciti alla community e investi</h2>
                </div>`;
        card3HTML = `
                <div class="GamersLandingCard heading4">
                    <h2>Guadagna dai giochi che supporti</h2>
                </div>`;
    }

    // Create and add cards to the scene
    const card1 = createCSS3DCard(card1HTML, pos1.add(offset1), 'GamersLandingCard');
    const card2 = createCSS3DCard(card2HTML, pos2.add(offset2), 'GamersLandingCard');
    const card3 = createCSS3DCard(card3HTML, pos3.add(offset3), 'GamersLandingCard');
    cssScene.add(card1);
    cssScene.add(card2);
    cssScene.add(card3);

    card1.scale.set(0.005, 0.005, 0.005);
    card2.scale.set(0.005, 0.005, 0.005);
    card3.scale.set(0.005, 0.005, 0.005);

    topModelsLoaded = true;
}

/**
 * This will remove the top models and their resources from memory.
 */
function unloadTopModelsAndCards() {
    if (!topModelsLoaded) return; // Nothing to unload

    // Remove the models from the scene
    if (rocketModel1) {
        scene.remove(rocketModel1);
        disposeGLTFModel(rocketModel1); // See dispose function below
        rocketModel1 = null;
    }

    if (rocketModel2) {
        scene.remove(rocketModel2);
        disposeGLTFModel(rocketModel2);
        rocketModel2 = null;
    }

    if (rocketModel3) {
        scene.remove(rocketModel3);
        disposeGLTFModel(rocketModel3);
        rocketModel3 = null;
    }

    // Clear the animatedButtons array of these entries
    animatedButtons.length = 0;

    // Remove CSS3D cards
    if (card1) {
        cssScene.remove(card1);
        card1 = null;
    }
    if (card2) {
        cssScene.remove(card2);
        card2 = null;
    }
    if (card3) {
        cssScene.remove(card3);
        card3 = null;
    }

    topModelsLoaded = false;
}

/**
 * This will dispose of a GLTF model’s resources.
 */
function disposeGLTFModel(model) {
    model.traverse((object) => {
        if (!object.isMesh) return;

        if (object.geometry) {
            object.geometry.dispose();
        }

        if (object.material) {
            // If the material has textures, they should also be disposed
            if (object.material.map) {
                object.material.map.dispose();
            }
            if (object.material.normalMap) {
                object.material.normalMap.dispose();
            }
            if (object.material.roughnessMap) {
                object.material.roughnessMap.dispose();
            }
            if (object.material.metalnessMap) {
                object.material.metalnessMap.dispose();
            }
            // ... Dispose of any other used maps

            object.material.dispose();
        }
    });
}
function InitTouch() {
    let mouseposition = { x: 0, y: 0 };
    let lastTouch = null;

    function onTouchStart(event) {
        if (event.touches.length > 0) {
            const touch = event.touches[0];
            lastTouch = { x: touch.pageX, y: touch.pageY };
        }
    }

    function onTouchMove(event) {
        event.preventDefault(); // Prevent the default scrolling or zooming
        if (event.touches.length > 0 && lastTouch) {
            const touch = event.touches[0];
            const deltaX = touch.pageX - lastTouch.x;
            const deltaY = touch.pageY - lastTouch.y;

            // Normalize these values for rotation if needed
            mouseposition.x += deltaX * 0.001; // adjust sensitivity as needed
            mouseposition.y += deltaY * 0.001;

            lastTouch = { x: touch.pageX, y: touch.pageY };
        }
    }

    function onTouchEnd() {
        lastTouch = null;
    }

    // Add these event listeners to your renderer's DOM element or window:
    renderer.domElement.addEventListener('touchstart', onTouchStart, { passive: false });
    renderer.domElement.addEventListener('touchmove', onTouchMove, { passive: false });
    renderer.domElement.addEventListener('touchend', onTouchEnd, { passive: false });


}
// Declare arrays to hold portals and cameras
let portals = [];
let portalCameras = [];
let portalCards = [];
const businessModelCards = [
    `<div>
        <h1>Perché scegliere Stratos?</h1>
        <p>Stratos non è solo una piattaforma di crowdfunding:
        qui, investitori, gamer e developer collaborano attivamente per creare giochi di successo. </p>

        <p>Gli investitori su Stratos non si limitano a finanziare un progetto; diventano parte del
        processo
        creativo, offrendo feedback diretto e influenzando lo sviluppo dei giochi in cui credono.</p>
    </div>`,

    `<div>
        <h1>Cosa si ottiene?</h1>
        <p>Su Stratos, i gamer non si limitano a giocare in anteprima: possono investire nei giochi,
        seguirne lo
        sviluppo da vicino e avere un contatto diretto con i developer.</p>

        <p>Non solo fai parte del processo creativo, ma puoi anche guadagnare dal successo del progetto.</p>
    </div>`,

    `<div>
        <h1>Come si guadagna?</h1>
        <p>Sostenere un gioco su Stratos ti premia!</p>

        <p>Grazie al nostro sistema di revenue shares, guadagnerai una parte dei ricavi, permettendoti di
        acquistare ancora più giochi.</p>
    </div>`,

    `<div>
        <h1>Domina il mercato del gaming</h1>
        <p>Con Stratos, il giocatore riacquista il controllo, ha voce in capitolo su ciò che viene
        pubblicato,
        influenzando direttamente il mercato.</p>

        <p>Diventa parte attiva della rivoluzione del gaming e fai sentire la tua opinione!</p>
    </div>`,

    `<div>
        <h1>Analizza le tendenze</h1>
        <p>La community offre un'opportunità unica: confrontarsi con videogiocatori e professionisti per
        scoprire le
        ultime novità sul mercato, esplorare investimenti potenziali e immergersi nei giochi in fase di
        sviluppo.</p>

        <p>Unisciti a noi e fai sentire la tua voce!</p>
    </div>`,

    `<div>
        <h1>Entra nel gioco del networking!</h1>
        <p>La community ti avvicina al mondo del gaming attraverso eventi esclusivi e Q&A con sviluppatori,
        favorendo opportunità di networking e collaborazioni con istituzioni accademiche.</p>
    </div>`
];
const portalTextureURLs = [
    new URL('../assets/img/WhyStratosRender.webp', import.meta.url),
    new URL('../assets/img/WhatStratosRender.webp', import.meta.url),
    new URL('../assets/img/HowStratosRender.webp', import.meta.url),
    new URL('../assets/img/DominatesStratosRender.webp', import.meta.url),
    new URL('../assets/img/AnalyzeStratosRender.webp', import.meta.url),
    new URL('../assets/img/NetworkingStratosRender.webp', import.meta.url),
];
async function createPortals() {
    const portalPlaneAspect = 4 / 2.6; // width / height of the portal plane
    const portalPlaneDimensions = { width: 5.25, height: 2.8 };

    const yStart = -7.5; // Starting Y position
    const yStep = -3.5; // Step between each portal on the Y-axis
    const xPositions = [-2.7, 2.7]; // Alternating X positions for portals

    const cardXPositions = [3, -3]; // Alternating X positions for texts

    // Define the portal textures

    // Portal data
    const portalsData = portalTextureURLs.map((textureURL, index) => ({
        position: new THREE.Vector3(
            xPositions[(index + 1) % 2],
            yStart + yStep * (index + 1),
            -0.5
        ),
        textureType: 'equirectangular',
        textureURL: textureURL,
        cameraFov: 70,
    }));

    // Create portals and corresponding texts
    portalsData.forEach((portalData, index) => {
        const portalScene = new THREE.Scene();

        const portalCamera = new THREE.PerspectiveCamera(
            portalData.cameraFov,
            portalPlaneAspect,
            0.01,
            -1
        );
        portalCamera.position.z = 1;
        portalCameras.push(portalCamera);
        const textureURL = new URL(portalData.textureURL, import.meta.url);

        loader.load(
            textureURL.href,
            function (texture) {
                texture.mapping = THREE.EquirectangularRefractionMapping;
                portalScene.background = texture;
            },
            undefined,
            function (err) {
                console.error(
                    'An error occurred loading the texture:',
                    err
                );
            }
        );

        // Create the portal plane
        const portal = createPortalPlane(renderer, portalScene, portalCamera, {
            width: portalPlaneDimensions.width,
            height: portalPlaneDimensions.height,
            position: portalData.position,
            radius: 0.4,
            index: index,
            backgroundURL: portalData.textureURL,
        });
        portals.push(portal);

        scene.add(portal.portalPlane);

        // Create the CSS3DCard (text)
        const cardXPosition = cardXPositions[(index + 1) % 2];
        const cardYPosition = yStart + yStep * (index + 1);
        const cardZPosition = portalData.position.z;

        const cardPosition = new THREE.Vector3(cardXPosition, cardYPosition, cardZPosition);

        const card = createCSS3DCard(businessModelCards[index], cardPosition, 'businessModelCard');
        portalCards.push(card);
        cssScene.add(card);
        card.scale.set(0.008, 0.008, 0.008);
    });
}



async function createPortals_mobile() {
    const portalPlaneAspect = 1; // width / height of the portal plane
    const portalPlaneDimensions = { width: 2, height: 2 };

    const yStart = -10.5; // Starting Y position
    const yStep = -5.3; // Step between each portal on the Y-axis
    const xPositions = [0, 0]; // Alternating X positions (right and left)


    // Portal data
    const portalsData = [
        ...portalTextureURLs.map((textureURL, index) => ({
            position: new THREE.Vector3(
                xPositions[(index + 1) % 2],
                yStart + yStep * (index + 1),
                1
            ),
            textureType: 'equirectangular',
            textureURL: textureURL,
            cameraFov: 70,
        })),
    ];

    // Create portals (plane, scene, camera)
    portalsData.forEach((portalData, index) => {
        const portalScene = new THREE.Scene();

        const portalCamera = new THREE.PerspectiveCamera(
            portalData.cameraFov,
            portalPlaneAspect,
            0.01,
            10
        );
        portalCamera.position.z = 1;
        portalCameras.push(portalCamera);
        if (!isMobile || index === 0) {
            // Load the texture
            const textureURL = new URL(portalData.textureURL, import.meta.url);

            loader.load(
                textureURL.href,
                function (texture) {
                    texture.mapping = THREE.EquirectangularRefractionMapping;
                    portalScene.background = texture;
                },
                undefined,
                function (err) {
                    console.error(
                        'An error occurred loading the texture:',
                        err
                    );
                }
            );
        }

        // Create the portal plane
        const portal = createPortalPlane(renderer, portalScene, portalCamera, {
            width: portalPlaneDimensions.width,
            height: portalPlaneDimensions.height,
            position: portalData.position,
            radius: 0.03,
            index: index,
            backgroundURL: portalData.textureURL,
        });
        portals.push(portal);
        scene.add(portal.portalPlane);

        const card = createCSS3DCard(businessModelCards[portals.length - 1], portalData.position, 'businessModelCard');
        requestAnimationFrame(() => {
            const height = card.element.offsetHeight;
            card.position.y = (portalsData[index].position.y + height / 100) + 0.2;
        });
        cssScene.add(card);

        card.scale.set(0.01, 0.01, 0.01);
    });
}

// Fade in the canvas when entering the scene from another page
async function fadeInCanavas() {
    gsap.to('canvas', {
        opacity: 1,
        duration: 0.5,
        delay: 1 // Starts after the camera animation completes
    });
}
const mouseWorldPosition = new THREE.Vector3();
const mouseposition = new THREE.Vector2();
// Update the mouse position
window.addEventListener('mousemove', function (e) {
    mouseposition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouseposition.y = - (e.clientY / window.innerHeight) * 2 + 1; // Corrected calculation
});


const raycaster = new THREE.Raycaster();

function animate(time) {
    const deltaTime = clock.getDelta();
    elapsedTime = clock.getElapsedTime();

    // animate camera (scrolldown animation)
    if (isAnimatingCamera_gamers) {
        let t = (elapsedTime - cameraAnimationStartTime) / cameraAnimationDuration;
        if (t >= 1) {
            t = 1;
            isAnimatingCamera_gamers = false;
        }

        // Interpolate camera position
        camera.position.lerpVectors(cameraStartPosition, cameraEndPosition, t);

        // Interpolate camera rotation
        camera.quaternion.slerpQuaternions(cameraStartQuaternion, cameraEndQuaternion, t);
    }



    //Animate the portals: find the closest portal
    if (!isAnimatingCamera_gamers && !isTop) {
        animatePortals();
    }

    animateModelsOnHover(animatedButtons, deltaTime, raycaster, mouseposition, camera);
    // calcola la posizione nuova delle stelle
    const vector = new THREE.Vector3(mouseposition.x, mouseposition.y, 0.5); // z = 0.5 for middle of the frustum
    vector.unproject(camera);
    mouseWorldPosition.copy(vector);
    // Update the shader uniforms
    if (galaxyMaterial) {
        galaxyMaterial.uniforms.uMouse.value.copy(mouseWorldPosition);
        galaxyMaterial.uniforms.uTime.value = elapsedTime;
    }
    renderer.render(scene, camera);
    cssRenderer.render(cssScene, camera);
}

let cameraAnimationStartTime = 0;
let cameraAnimationDuration = 0.5; // Duration in seconds
let cameraStartPosition = new THREE.Vector3();
let cameraEndPosition = new THREE.Vector3();
let cameraStartQuaternion = new THREE.Quaternion();
let cameraEndQuaternion = new THREE.Quaternion();
let previousClosestIndex = -1; // Declare outside animatePortals

async function animatePortals() {
    let closestPortal = null;
    let minDistance = Infinity;

    portals.forEach((portal, index) => {
        const distance = camera.position.distanceTo(portal.portalPlane.position);
        if (distance < minDistance) {
            minDistance = distance;
            closestPortal = { portal, index };
        }
    });

    // Check if the closest portal has changed
    const closestIndex = closestPortal ? closestPortal.index : -1;
    if (closestIndex !== previousClosestIndex) {
        // Smoothly interpolate from the previous closest camera's rotation to the new one
        const targetCamera = portalCameras[closestIndex];
        const previousCamera = portalCameras[previousClosestIndex];

        if (previousCamera && targetCamera) {
            targetCamera.rotation.x = THREE.MathUtils.lerp(previousCamera.rotation.x, mouseposition.x, 0.1);
            targetCamera.rotation.y = THREE.MathUtils.lerp(previousCamera.rotation.y, mouseposition.y, 0.1);
        }

        // Update the previous closest index
        previousClosestIndex = closestIndex;
        if (isMobile()) {
            portals.forEach((portal, index) => {
                if (index !== closestIndex && portal._texture) {
                    if (portal.scene) {
                        portal.scene.background = null;
                        portal.disable();
                        if (portal._texture) {
                            portal._texture.dispose();
                            portal._texture = null;
                        }

                    }
                }
                else if (portal.scene && index === closestIndex) {
                    portal.enable();
                    const textureURL = new URL(portal.backgroundURL, import.meta.url);
                    loader.load(
                        textureURL.href,
                        function (texture) {
                            texture.mapping = THREE.EquirectangularRefractionMapping;
                            portal.scene.background = texture;
                            portal._texture = texture;
                        },
                        undefined,
                        function (err) {
                            console.error('An error occurred loading the texture:', err);
                        }
                    );

                }
            });
        }
    }

    // Render all portals with rotation adjustments
    portals.forEach((portal, index) => {
        const portalCamera = portalCameras[index];

        if (closestIndex === index) {
            // Move the portal camera based on the mouse position with direct control
            portalCamera.rotation.y = mouseposition.x * 2 - (Math.PI / 2);
            // Render the closest portal with smooth interpolation
            portal.renderPortal();

        } else if (!isMobile()) {
            // Set rotation for other portals and render them
            portalCamera.rotation.y = 0.5 * Math.cos(elapsedTime / 2) - (Math.PI / 2);
            portal.renderPortal();
        }
    });
}
async function unloadPortals() {
    if (portals.length === 0) return;

    // Remove each portal and dispose resources
    portals.forEach((portal, index) => {
        // Remove from scene
        scene.remove(portal.portalPlane);

        // Dispose portal plane geometry and material
        if (portal.portalPlane.geometry) portal.portalPlane.geometry.dispose();
        if (portal.portalPlane.material && portal.scene) {
            console.log('material and texture disposing');
            portal.portalPlane.material.dispose();
            portal.scene.background = null;
            if (portal._texture) portal._texture.dispose();
        }

        // Dispose background texture if it exists
    });

    // Remove associated CSS3D cards
    portalCards.forEach(card => {
        if (card) cssScene.remove(card);
    });

    // Clear arrays
    portals = [];
    portalCameras = [];
    portalCards = [];
}
export function animateCameraToBottom_gamers() {
    //log
    if (isAnimatingCamera_gamers) return;
    isTop = false;
    isAnimatingCamera_gamers = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation
    cameraEndPosition.set(0, -7, 4); // Move down and closer
    camera.lookAt(0, 1.5, 0); // Adjust to look at a lower point
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);


    // Create and add the portals
    if (isMobile()) {
        unloadTopModelsAndCards();
        InitTouch();
        createPortals_mobile();

    } else {
        createPortals();
    }
}
export function animateCameraToTop_gamers() {
    if (isAnimatingCamera_gamers) return;
    isTop = true;
    isAnimatingCamera_gamers = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation (original position)
    cameraEndPosition.set(0, 1.5, 4.5); // Original position
    camera.lookAt(0, -7.5, 0); // Original lookAt
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
    if (isMobile()) {

        loadTopModelsAndCards();
        unloadPortals();
    }
}